<template>
  <div class="interval-option">
    <label class="sa-ps-ia-title">间隔</label>
    <div class="sa-ps-ia-input">
      <OptionSelect v-model="valueTemp"
                 filterable
                 allow-create
                 default-first-option
                 @change="handleSelectionChanged"
                 class="m-2"
                 placeholder="请选择间隔"
                 size="large">
          <el-option
              v-for="item in TIME_INTERVAL"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
      </OptionSelect>
    </div>
    <span class="io-tip">
      Select an option or create a custom value. Examples: 30s, 20m, 24h, 2d, 1w, 1M
    </span>
  </div>
</template>

<script>
import {TIME_INTERVAL, AGGREGATIONS, controlPanelOptionTree, SETTING_ITEM_OPTIONS} from "../js/statisticChartConfig";
import {colorLog} from "../../../util";
import OptionSelect from "./common/OptionSelect";

export default {
  name: "IntervalOption",
  components: {OptionSelect},
  props:{
    value:{
      required:false,
      default:TIME_INTERVAL.AUTO
    },
    aggregation:{
      required:true
    }
  },
  data(){
    return {
      TIME_INTERVAL,
      AGGREGATIONS,
      valueTemp:null,
    }
  },

  watch:{
    value:{
      handler(val){
        this.valueTemp = val;
      },
      immediate:true
    }
  },
  computed:{

  },
  methods:{
    handleSelectionChanged(val){
      this.$emit('update:value',val)
    }
  },

}
</script>
<style scoped>
.io-tip{
  white-space: initial;
  color:var(--font-gray);
}
</style>
<style scoped lang="scss">
@import "../scss/option";

</style>
