<template>
  <div class="filter-setting-middle">
    <div class="middle-first-row">
      <div class="row-item">
        <p class="row-item-title">字段</p>
        <el-select v-model="filter.field"
                   :popper-class="popoverClass"
                   @change="handleFilterFieldChange">
                <el-option-group
                    v-for="group in filterSettingFields"
                    :key="group.type"
                    :label="group.type"
                >
                  <el-option
                      v-for="item in group.fields"
                      :key="item"
                      :label="item"
                      :value="item"
                  />
                </el-option-group>
        </el-select>
      </div>
      <div class="row-item">
        <p class="row-item-title">操作</p>
        <el-select :disabled="!filter.field"
                   :popper-class="popoverClass"
                   :placeholder="operatorPlaceholder"
                   v-model="filter.operator">
          <el-option
              v-for="item in currentOperators"
              :key="item[0]"
              :label="item[1]"
              :value="item[1]"
          />
        </el-select>
      </div>
    </div>
    <FilterSettingValueOption
        class="middle-second-row"
        v-if="filter.field && filter.operator && filterSettingValueVisible"
        :filter-setting-values="filterSettingValues"
        :popover-class="popoverClass"
        :current-field-type="currentFieldType"
        :filter="filter"
        :isLoadingValuesOfField="isLoadingValuesOfField"
    >
    </FilterSettingValueOption>
  </div>
  <div class="filter-setting-bottom">
    <el-button size="mini" class="rect-btn" type="default" @click="handleCancel">取消</el-button>
    <el-button size="mini" class="rect-btn" type="primary"
               :disabled="isFilterSettingSaveDisable"
               @click="handleSave">保存
    </el-button>

  </div>
</template>

<script>
import {colorLog, deepClone} from "../../util";
import {
  FILTER_OPERATOR,
  FILTER_EXCLUDE_OPERATOR,
  FIELD_TYPES,
  FILTER_OPERATORS,
  FILTER_OPERATORS_OF_TYPE
} from "./js/statisticChartConfig";
import FilterSettingValueOption from "./options/FilterSettingValueOption";
import {filterValueValidate, getFieldType, isFilterOperatorSimilar} from "./js/chartUtil";
import {getVisualizationTermsData} from "../../api/api";
import {ElMessage} from "element-plus";

export default {
  name: "StatisticFilterSetting",
  components: {FilterSettingValueOption},
  emits:['onCancel','onSave'],
  props:{
    data:{
      required:true
    },
    popoverClass:{
      default:'setting-popover'
    },
    filterSettingFields:Object,
    filterSettingValues:Object
  },
  data(){
    const defaultFilterSettingValues = {
      value: '',
      values:[],
      valueRange:{start:'',end:''},
      timeRange:{start:'',end:''}
    }
    return {
      FIELD_TYPES,
      FILTER_OPERATORS,
      FILTER_EXCLUDE_OPERATOR,
      FILTER_OPERATORS_OF_TYPE,
      defaultFilterSettingValues,
      filterSettingValueVisible: true,
      operatorPlaceholder:'等待中',
      filter: null,
      //状态值
      isLoadingValuesOfField:false, //字段的值为'关键字'类型时,向服务器拿值时为true(正在加载数据)
    }
  },
  watch:{
    '$props.data':{
      handler:function(val){
        this.filter = deepClone(val);
        colorLog.blue('$props.data',this.filter)

      },
      immediate:true,
      deep:true
    },
    'filter':{
      handler:function(val){
        this.operatorPlaceholder =  val.field ? "请选择" : "等待中";
      },
      deep:true,
      // immediate: true
    },
    //更改'关键字'类型的字段刷新'可选值'列表
    'filter.field':{
      handler:function(newVal,oldVal){
        if (!newVal) return;
        this.updateFieldValues(newVal);
        let newFieldType = getFieldType(this.filterSettingFields,newVal);
        let oldFieldType = getFieldType(this.filterSettingFields,oldVal);

        if(!FILTER_OPERATORS_OF_TYPE[this.currentFieldType].includes(this.filter.operator)){
          this.filter.operator = null;
        }
        if(newFieldType != oldFieldType || newFieldType == FIELD_TYPES.STRING){
          this.clearFilterSettingValues();
          this.remountSettingValue();
        }
      },
    },
    //更改'操作'时要重刷一遍'值'的组件
    'filter.operator': function (newVal) {
      this.remountSettingValue();
    }
  },
  computed:{
    currentFieldType(){
      return getFieldType(this.filterSettingFields,this.filter.field);
    },
    currentOperators(){
      // colorLog.red('currentFieldType',this.currentFieldType)
      if(!this.currentFieldType) return null;
      return Object.entries(FILTER_OPERATORS_OF_TYPE[this.currentFieldType]);
    },
    isFilterSettingSaveDisable() {
      let {field, operator} = this.filter;
      let valueValid = false;
      valueValid = filterValueValidate(this.filter,this.currentFieldType,operator);
      // colorLog.blue('isFilterSettingSaveDisable',this.filter,!(field && operator && valueValid));
      return !(field && operator && valueValid);
    },
  },
  methods:{
    handleCancel() {
      this.$emit('onCancel',this.filter);
    },
    handleSave() {
      const obj = deepClone(this.filter);
      this.$emit('onSave', {
        filter:obj,
        fieldType:this.currentFieldType
      });
    },
    handleFilterFieldChange(field) {

    },

    async updateFieldValues(field){
      if(getFieldType(this.filterSettingFields,field) != FIELD_TYPES.STRING) return;
      this.isLoadingValuesOfField = true;
      let that = this;
      try {
        const res = await getVisualizationTermsData(field);
        that.$emit('update:filterSettingValues',res.data.data)
      }catch (e){
        colorLog.red(e);
      }finally {
        this.isLoadingValuesOfField = false;
      }
    },
    //将设置过滤值的组件刷新一遍
    remountSettingValue() {
      this.filterSettingValueVisible = false;
      this.$nextTick(() => {
        this.filterSettingValueVisible = true;
      })

    },

    clearFilterSettingValues(){
      this.$nextTick(()=>{
        Object.assign(this.filter,deepClone(this.defaultFilterSettingValues));
      })
    },
    clearFilter() {
      this.$nextTick(() => {
        this.filter.field = '';
        this.filter.operator = '';
        Object.assign(this.filter,deepClone(this.defaultFilterSettingValues));
      })
    }
  }
}
</script>

<style>
.filter-setting-popper .el-popover__title {
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--hoverLightBlue);
}

</style>

<style scoped>


.filter-setting-bottom {
  text-align: right;
  margin-top: 15px;
}

.middle-first-row {
  display: flex;
  justify-content: space-between;
}

.middle-first-row .row-item {
  flex: 0 0 48%;
}

.row-item-title {
  margin: 5px 0 5px 0;
}

.row-item {

}
</style>
