<template>
  <FilterOption
      v-for="(item,index) in filtersTemp"
      class="sa-ps-input-item"
      v-model:filter="filtersTemp[index]"
      :filters="filtersTemp"
      :fields="fields"
      :aggregation="aggregation"
      :index="index + 1"
      @delete="handleFilterOptionDelete(index)"
      :key="filtersTemp[index].id"
  ></FilterOption>
  <AddFilterUnit
      @click="handleAddFilterBtnClicked"
      class="sa-ps-input-item"
  ></AddFilterUnit>
</template>

<script>
import FilterOption from "./FilterOption";
import {SETTING_ITEM_OPTIONS,controlPanelOptionTree} from "../js/statisticChartConfig";
import {colorLog, deepClone, getUniqueId,} from "../../../util";
import AddFilterUnit from "./unit/AddFilterUnit";

export default {
  name: "FilterGroup",
  props:{
    fields:{
      required: true,
    },
    filters:{
      required: true,
    },
    aggregation:{
      required: true,
    }
  },
  data(){
    const defaultFilter = {
      field:'',
      operator:'',
      value:'',
      label:'',
      id:getUniqueId('df')
    }

    return {
      controlPanelOptionTree,
      SETTING_ITEM_OPTIONS,
      defaultFilter,
      filtersTemp:null,
    }
  },
  watch:{
    'filters':{
      handler(val){
        if(!val || val.length == 0){
          let newFilter = deepClone(this.defaultFilter);
          this.$emit('update:filters',[newFilter]);
          return;
        }
        this.filtersTemp = val;
      },
      immediate:true,
    }
  },
  created() {
    // colorLog.pink('FilterGroup filtersTemp',this.config[SETTING_ITEM_OPTIONS.FIlTERS])
  },
  methods:{
    isDisplayable(option){
      if(!this.aggregation) return false;
      return controlPanelOptionTree[this.aggregation].includes(option);
    },
    handleAddFilterBtnClicked(){
      let newFilter = deepClone(this.defaultFilter);
      newFilter.id = getUniqueId(`nf-${this.filtersTemp.length}`);

      this.filtersTemp.push(newFilter);
    },
    handleFilterOptionDelete(index){
      this.filtersTemp.splice(index,1);
    }
  },
  components:{AddFilterUnit, FilterOption}
}
</script>

<style scoped>
.filter-option + .filter-option{
  margin-top: 10px;
}
</style>
