<template>
  <div class="sa-ps-ia-input number-input-unit"
       :class="{invalid}"
  >
    <el-input v-model="valueTemp"
              placeholder="请输入"
              class="value-input" type="number"
              @input="handleInputChange"></el-input>
    <span  v-if="invalid"
           class="el-icon-warning value-invalid"></span>
    <span class="value-delete el-icon-delete color-error-hover"
          :class="{disabled}"
          @click="handleDeleteBtnClicked"
    ></span>
    <span v-if="invalid" class="valid-invalid-tip">
      {{currentInvalidTip}}
    </span>
  </div>
</template>

<script>
import {colorLog, singleListenerManager} from "../../../../util";
import {isNotNull} from "../../js/chartUtil";

export default {
  name: "NumberInputUnit",
  props:{
    value:{
      required:false,
      default:0
    },
    disabled:{
      required: false,
      default:false
    },
    validations:{
      required:false,
    },
    id:{
      required:true
    }

  },
  data(){
    return {
      valueTemp:null,
      invalid:false,
      toolTipVisible:false,
      currentInvalidTip:''
    }
  },
  watch:{
    value:{
      handler:function(val){
        this.valueTemp = val;
        this.validateValue();
      },
      immediate:true
    }
  },
  created() {
    // this.validateValue(this.value);
  },
  beforeUnmount() {

  },
  methods:{
    handleInputChange(val){
      if(val !== ''){
        val = Number(val);
      }
      this.$emit('update:value',val);
      let valid = this.validateValue(val);
    },
    handleDeleteBtnClicked(){
      this.$emit('delete');
    },
    validateValue(val){
      if(!this.validations || this.validations.length == 0){
        return true;
      }

      if(arguments.length == 0){
        val = this.valueTemp;
      }

      let allValid = this.validations.every(validation => {
        if(typeof validation.handler === 'function'
            && !validation.handler(val,this.id)
        ){
          allValid = false
          this.invalid = true;
          this.currentInvalidTip = validation.tip;
          return false;
        }

        return true;
      })

      if(allValid){
        this.invalid = false;
      }
      return !this.invalid;
    }
  }
}
</script>
<style scoped>

.number-input-unit:not(:first-of-type){
  margin-top: 16px;
}
.invalid >>> .el-input__inner{
  border-color:var(--el-color-error);
}
</style>
<style scoped lang="scss">
@import "../../scss/option";
</style>
