<template>
  <div class="statistic-container">
    <div class="statistic-top-bar">
      <StatisticToolbar
        @save="handleToolBarSaveBtnClicked"
        @share=""
        @refresh=""
      ></StatisticToolbar>
    </div>
    <div class="statistic-filter-bar">
      <StatisticFilter
      :filterSettingFields="filterSettingFields"
      :filters="filters"
      ></StatisticFilter>
    </div>
    <div class="statistic-date-picker"
         :class="{empty:timeRangeEmpty}"
    >
      <el-date-picker
          v-model="timeRange"
          value-format="YYYY-MM-DD h:m:s a"
          type="datetimerange"
          unlink-panels
          range-separator="到"
          start-placeholder="起始时间"
          end-placeholder="结束时间"
          :shortcuts="shortcuts"
          @change="timeRangeChanged"
          ref="timeRangeRef"
      />
    </div>
    <main class="statistic-main">
      <div
          v-show="isControlPanelExpanded"
          class="sm-left-control-panel"
           ref="controlPanel">

        <div class="sm-lcp-sample-set-title">
          <span class="sm-lcp-sample-set-title-wrap truncate">{{ sampleId }}</span>

        </div>
        <div class="sm-lcp-panel-setting-bar">
          <el-button type="primary" @click="loadChartData" :disabled="!canGenerate">生成</el-button>
          <el-button type="default" :disabled="!configSettingChanged" @click="resetSettingData">撤销</el-button>
        </div>
        <div class="sm-lcp-setting-area custom-scroller">
          <div class="sm-lcp-sa-panel">
            <h3 class="sm-lcp-sa-panel-title">Metrics</h3>
            <StatisticSettingItem
                :ref="pushVnode"
                :key="config.id"
                v-for="(config,index) in metricsConfigs"
                :aggregations="metricsAggregations"
                :fields="filterSettingFields"
                :config="config"
                :title="config.type"
            >
              <template #tools v-if="metricsConfigs.length > 1">
                <el-tooltip
                show-after="500"
                hide-after="0"
                content="禁用"
                :disabled="isLastOne(metricsConfigs,index)"
                >
                  <span @click="handlePanelVisibleBtnClicked(metricsConfigs,index)"
                        class="sm-panel-tool sm-panel-disable el-icon-view color-blue-hover"
                        :class="{
                         disabled:config.disabled,
                         last:!config.disabled && isLastOne(metricsConfigs,index)
                  }"
                  >
                  </span>
                </el-tooltip>

                <span @click="handlePanelCloseBtnClicked(true,index)" class="sm-panel-tool sm-panel-close el-icon-close color-error-hover"></span>
              </template>
            </StatisticSettingItem>
            <AddConfigOptionUnit
                :chart-type="chartType"
                :configs="metricsConfigs"
                :config-type="CONFIG_TYPES.METRICS"
                @add="handleAddPanelBtnClicked"
                class="sm-outer-add"
            ></AddConfigOptionUnit>
          </div>

          <div class="sm-lcp-sa-panel">
            <h3 class="sm-lcp-sa-panel-title">Buckets</h3>
            <StatisticSettingItem
                :ref="pushVnode"
                :key="config.id"
                v-for="(config,index) in bucketsConfigs"
                :aggregations="bucketsAggregations"
                :fields="filterSettingFields"
                :metricsConfigs="metricsConfigs"
                :config="config"
                :title="config.type"
            >
              <template #tools>
                <el-tooltip
                show-after="200"
                hide-after="0"
                content="禁用"
                >
                  <span @click="handlePanelVisibleBtnClicked(false,index)"
                        class="sm-panel-tool sm-panel-disable el-icon-view ctm-tooltip color-blue-hover"
                        :class="{
                    disabled:config.disabled
                  }"
                  >
                  </span>
                </el-tooltip>

                <span @click="handlePanelCloseBtnClicked(false,index)" class="sm-panel-tool sm-panel-close el-icon-close color-error-hover"></span>
              </template>
            </StatisticSettingItem>

            <AddConfigOptionUnit
                :chart-type="chartType"
                :configs="bucketsConfigs"
                :config-type="CONFIG_TYPES.BUCKETS"
                @add="handleAddPanelBtnClicked"
                class="sm-outer-add"
            ></AddConfigOptionUnit>
          </div>
        </div>
      </div>
      <StatisticControlPanelResize
          v-if="isControlPanelExpanded"
          :resize-target="getControlPanelRef"
      ></StatisticControlPanelResize>
      <div class="sm-left-control-panel-minimize-bar">
        <div class="sm-left-control-panel-minimize-btn"
             @click="handleMinimizeControlPanelClicked">
            <i class="el-icon-arrow-up minimize-arrow"
               :class="{'minimize-arrow--minimized':!isControlPanelExpanded}"
               ref="minimize-arrow"
            />
        </div>
      </div>
      <div class="sm-right-chart-view custom-scroller">
            <component
                :is="statisticComponent"
                v-if="chartData"
                :chart-data="chartData"
                :x_Axis="xAxis"
                :_legend="legend"
                :min-width="'400px'"
            ></component>
      </div>
    </main>

    <span>统计类型:{{ chartType }}</span>

    <SaveDialog
        :visible="saveDialogVisible"
        @close="saveDialogVisible = false"
        @save="saveConfig"
    ></SaveDialog>
  </div>
</template>

<script>
import {
  getChartData,
  getChartFields,
  getElasticTableInfo,
  saveVisualization,
  updateVisualization,
  visualizationSearch
} from "../api/api";
import {
  AGGREGATIONS, BUCKETS_AGGREGATIONS, TIME_INTERVAL,
  SETTING_ITEM_OPTIONS,
  CHART_TYPES,
  METRICS_AGGREGATIONS, ORDER_SORT, FILTER_OPERATORS, FIELD_TYPES,
  CONFIG_TYPE_OPTIONS, CONFIG_TYPES, CHART_EVENTS
} from "../components/charts/js/statisticChartConfig";
import {isEqual} from 'lodash';
import {colorLog, deepClone, getHashColor, getUniqueId, MetaMessage, singleListenerManager} from "../util";
import {toRaw} from "vue";
import { defineAsyncComponent } from 'vue'
import StatisticFilter from "../components/charts/StatisticFilter";
import '../assets/css/customScroller.css'
import StatisticSettingItem from "../components/charts/StatisticSettingItem";
import StatisticControlPanelResize from "../components/charts/StatisticControlPanelResize";
import AddOptionUnit from "../components/charts/options/unit/AddOptionUnit";
import {filterParam} from "../components/charts/js/chartUtil";
import {ElMessage} from "element-plus";
import AddConfigOptionUnit from "../components/charts/options/unit/AddConfigOptionUnit";
import StatisticToolbar from "../components/charts/toolbar/StatisticToolbar";
import Mask from "../components/universalUI/Mask";
import SaveDialog from "../components/charts/toolbar/SaveDialog";

export default {
  name:'Statistic',
  components: {
    SaveDialog,
    Mask,
    StatisticToolbar,
    AddConfigOptionUnit,
    AddOptionUnit,
    StatisticControlPanelResize,
    StatisticSettingItem,
    StatisticFilter,
    StatisticRank: defineAsyncComponent(() => import('../components/charts/StatisticRank')),
    StatisticLine: defineAsyncComponent(() => import('../components/charts/StatisticLine')),
    StatisticBar: defineAsyncComponent(() => import('../components/charts/StatisticBar')),
    StatisticPie: defineAsyncComponent(() => import('../components/charts/StatisticPie'))
  },
  props:{

  },
  data(){
    let chartType = CHART_TYPES[this.$route.query.statisticType];

    let defaultBucketConfig = {
      id:getUniqueId('bk'),
      title:getUniqueId('bk'),
      aggregation:'',
      disabled:false,
      [SETTING_ITEM_OPTIONS.ORDER_BY]:null,
      [SETTING_ITEM_OPTIONS.ORDER_SORT]: {
        order:ORDER_SORT.DESCENDING,
        size:5
      },
      [SETTING_ITEM_OPTIONS.FIlTERS]:[],
      [SETTING_ITEM_OPTIONS.FIELD]:'',
      [SETTING_ITEM_OPTIONS.MINIMUM_INTERVAL]:TIME_INTERVAL.AUTO.value,
      [SETTING_ITEM_OPTIONS.CUSTOM_LABEL]:'',
    };
    let options = CONFIG_TYPE_OPTIONS[chartType][CONFIG_TYPES.METRICS];
    let defaultOptionName = Object.getOwnPropertyNames(options)[0];

    let defaultMetricConfig = {
      id:getUniqueId('mc'),
      title:getUniqueId('mc'),
      aggregation:'',
      disabled:false,
      type:defaultOptionName,
      [SETTING_ITEM_OPTIONS.FIELD]:'',
      [SETTING_ITEM_OPTIONS.VALUES]:[],
      [SETTING_ITEM_OPTIONS.PERCENTS]:[],
      [SETTING_ITEM_OPTIONS.CUSTOM_LABEL]:'',
    };

    return {
      //router query
      sampleId:this.$route.query.sampleId,

      isControlPanelExpanded:true,//展开左侧控制面板
      chartType,
      chartData:null,
      rawSamples:null,
      metricsBackup:null,
      bucketsBackup:null,
      timeRangeBackup:null,
      metricsAggregations: Object.entries(METRICS_AGGREGATIONS[chartType]),
      bucketsAggregations: Object.entries(BUCKETS_AGGREGATIONS),
      AGGREGATIONS,
      CONFIG_TYPES,
      defaultMetricConfig,
      defaultBucketConfig,
      metricsConfigs:null,
      bucketsConfigs:null,
      bucketsIntervals:Object.entries(TIME_INTERVAL),

      filterSettingFields: [
        // {
        //   label: 'id',
        //   selectable: true,
        // },
        // {
        //   label: 'versionCode',
        //   selectable: false,
        // },
        // {
        //   label: 'timeStamp',
        //   selectable: false,
        // }
      ],
      //伪数据 过滤器
      filters: [
        // {
        //   filter:{
        //     field: '1',
        //     operator: FILTER_OPERATORS.IS_ONE_OF,
        //     values: ["a", "b"],
        //   },
        //   fieldType:FIELD_TYPES.STRING,
        //   disabled:true
        // },
      ],

      //日期选择组件相关
      shortcuts:[
        {
          text: '最近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '最近一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '最近三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
        {
          text: '最近一年',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setFullYear(end.getFullYear() - 1);
            return [start, end]
          },
        },
      ],
      timeRange:[],
      settingVnodes:[],
      xAxis:{type: 'category',
        boundaryGap: false,
        data: []},
      currentFilterCount:0,
      legend:{data:[]},
      metaMessage:new MetaMessage(),
      _timeRange:{},
      saveDialogVisible:false,
      timeRangeEmpty:false,
      visualizationId:this.$route.query.id,
      title:this.$route.query.title,
      desc:this.$route.query.desc
    }
  },
  watch:{
    metricsConfigs:{
      handler(val){
        let oldQuery = deepClone(this.$route.query);
        let newVal = Object.assign(oldQuery,{metrics:JSON.stringify(val)});

        this.$router.replace({
          name:'statistic',
          query:newVal
        })
      },
      deep:true,
    },
    bucketsConfigs:{
      handler(val){
        let oldQuery = deepClone(this.$route.query);
        let newVal = Object.assign(oldQuery,{buckets:JSON.stringify(val)});

        this.$router.replace({
          name:'statistic',
          query:newVal
        })
      },
      deep:true,
    },
    timeRange:{
      handler:function(val){
        let oldQuery = deepClone(this.$route.query);
        let newVal = Object.assign(oldQuery,{timeRange:JSON.stringify(val)});
        this._timeRange={start:this.timeRange[0],end:this.timeRange[1]}
        this.$router.replace({
          name:'statistic',
          query:newVal
        })
      },
      deep:true
    },
    filters:{
      handler:function(val){
        let oldQuery = deepClone(this.$route.query);
        let newVal = Object.assign(oldQuery,{filters:JSON.stringify(val)});

        this.$router.replace({
          name:'statistic',
          query:newVal
        })
      },
      deep:true,
    }
  },
  computed:{
    statisticComponent(){
      switch (this.chartType){
        case CHART_TYPES.LINE:
          return 'StatisticLine';
        case CHART_TYPES.TABLE:
          return 'StatisticRank';
        case CHART_TYPES.PIE:
          return 'StatisticPie';
        case CHART_TYPES.BAR:
          return 'StatisticBar';
      }
    },
    configSettingChanged(){
      return !isEqual(this.metricsBackup,this.metricsConfigs)
          || !isEqual(this.bucketsBackup,this.bucketsConfigs);
    },
    canGenerate(){
      return this.configSettingChanged || !isEqual(this.timeRangeBackup,this.timeRange);
    }
  },
  methods:{
    pushVnode(e){
      this.settingVnodes.push(e);
    },
    isLastOne(configs,index){
      let flag = configs.reduce((sum,item,index)=>{
        if(!item.disabled){
          return ++sum;
        }
        return sum;
      },0) == 1 && (!configs[index].disabled || typeof index == 'undefined');

      return flag;
    },
    handleToolBarSaveBtnClicked(){
      if(this.checkValidation(true) && this.checkTimeRange(true)){
        this.saveDialogVisible = true;
      }
    },
    saveConfig(title,desc){
      let param = {
        chartType: this.chartType,
        filters: this.filters,
        timeRange:this._timeRange,
        configuration: {
          data: {
            metrics: this.metricsConfigs,
            buckets: this.bucketsConfigs,
            title,
            desc,
          }
        }
      }
      filterParam(param);
      if(this.visualizationId){
        updateVisualization(this.visualizationId,param).then(res=>{
          if (res.data.code === 0) {
            this.metaMessage.success("保存成功")
          } else {
            this.metaMessage.error("保存失败")
          }
          this.saveDialogVisible=false;
        }).catch(err => {
          this.metaMessage.error("保存失败")
        })

      }else {
        saveVisualization(param).then(res => {

          if (res.data.code === 0) {
            this.metaMessage.success("保存成功")
          } else {
            this.metaMessage.error("保存失败")
          }
          this.saveDialogVisible=false;

        }).catch(err => {
          this.metaMessage.error("保存失败")
        })
      }
    },
    handleAddPanelBtnClicked(configType,propName){
      colorLog.red('propName',propName);
      let newData;
      if(configType == CONFIG_TYPES.METRICS){
        newData = deepClone(this.defaultMetricConfig);
        newData.id = getUniqueId('mcIndex');
        newData.title = getUniqueId('mcIndex');
        newData.type = propName;
        this.metricsConfigs.push(newData);
      }else{
        newData = deepClone(this.defaultBucketConfig);
        newData.id = getUniqueId('bkIndex');
        newData.title = getUniqueId('bkIndex');
        newData.type = propName;
        this.bucketsConfigs.push(newData);
      }
    },
    handlePanelVisibleBtnClicked(isMetrics,index){
      function resolve(configs){
        if(this.isLastOne(configs,index) && isMetrics) return;
        configs[index].disabled = !configs[index].disabled;
      }
      resolve = resolve.bind(this);

      if(isMetrics){
        resolve(this.metricsConfigs);
      }else{
        resolve(this.bucketsConfigs);
      }
    },
    handlePanelCloseBtnClicked(isMetrics,index){
      function resolve(configs){
        configs.splice(index,1);
        if(configs.every(item => item.disabled) && isMetrics){
          configs[0].disabled = false;
        }
      }

      if(isMetrics){
        resolve(this.metricsConfigs);
      }else{
        resolve(this.bucketsConfigs)
      }
    },
    handleMinimizeControlPanelClicked(){
      this.isControlPanelExpanded = !this.isControlPanelExpanded;
    },
    resetSettingData(){
      this.bucketsConfigs = deepClone(this.bucketsBackup);
      this.metricsConfigs = deepClone(this.metricsBackup);
    },
    getControlPanelRef(){
        return this.$refs.controlPanel;
    },
    backupConfigs(){
        this.metricsBackup = deepClone(this.metricsConfigs);
        this.bucketsBackup = deepClone(this.bucketsConfigs);
    },
    initializeConfigs(){
      let { metrics, buckets, timeRange } = this.$route.query;

      try{
        if(!metrics) throw "url无信息";
        this.metricsConfigs = JSON.parse(metrics);
      }catch (e){
        this.metricsConfigs = [deepClone(this.defaultMetricConfig)];
        colorLog.red(e)
      }

      try{
        if(!buckets) throw "url无信息";
        this.bucketsConfigs = JSON.parse(buckets);
      }catch (e){
        this.bucketsConfigs = [];
      }

      try{
        if(!timeRange) throw "url无信息";
        let timeRange = JSON.parse(this.$route.query.timeRange);
        this._timeRange=timeRange;
        console.log(this.timeRange)
        this.timeRange.push(timeRange.start)
        this.timeRange.push(timeRange.end);
      }catch (e){
        const defaultDate = Date.now();
        let startDate = defaultDate - 24 * 60 * 60 * 1000;
        this.timeRange = [this.formatDate(startDate),this.formatDate(defaultDate)];
      }
    },
    formatDate(d){
      function addZero(str){
        str += '';
        str = (str.length == 1 ? '0':'') + str;
        return str;
      }
      // YYYY-MM-DD h:m:s a
      d = new Date(d);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let date = d.getDate();
      let hours = d.getHours();
      let minutes = d.getMinutes();
      let seconds = d.getSeconds();
      let ap = hours < 12 ? 'am' :'pm';

      return `${year}-${addZero(month)}-${addZero(date)} ${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)} ${ap}`;
    },
    //检查时间
    checkTimeRange(showTip){
      let flag = this.timeRange && this.timeRange.length === 2 && this.timeRange.every(o=>o);
      this.timeRangeEmpty = !flag;
      if(!flag && showTip){
        ElMessage.error('请选择时间段');
      }
      return flag;
    },
    //检查配置是否合法
    checkValidation(showTip){
      singleListenerManager.emit(CHART_EVENTS.CHECK_INPUT_VALIDATION);

      let configValid = this.settingVnodes.every(vnode => vnode.getSettingItemValidation());
      if(!configValid && showTip){
        ElMessage.error('请检查填写的数据是否合法');
      }
      return configValid;
    },
    async loadChartData(){
      colorLog.red('loadChartData');

      if(!this.checkValidation(true) || !this.checkTimeRange(true)) {

        return Promise.resolve();
      }

      this.metricsBackup = deepClone(this.metricsConfigs);
      this.bucketsBackup = deepClone(this.bucketsConfigs);
      this.timeRangeBackup = deepClone(this.timeRange);

      let param = {
        chartType: this.chartType,
        filters: this.filters,
        timeRange:this._timeRange,
        configuration: {
          data: {
            metrics: this.metricsConfigs,
            buckets: this.bucketsConfigs
          }
        }
      }
      filterParam(param);
      let res = await visualizationSearch(param);
      const makeItem = (item)=>{
        let result = {};
        result.name=item.name;
        if(item.count!==null){
          result.value=item.count;
        }else if(item.value!==null){
          result.value=item.value;
        }
        if(item.name=='过滤器'){
          let filters= this.bucketsConfigs.filter((item)=>item.aggregation=='过滤器')

          let filter = filters[this.currentFilterCount];

          if(item.aggs){
            let i = 0;
            item.aggs.forEach((subItem)=>{
              subItem.name=filter.filters[i++].value;

            })
          }
        }

        if(item.aggs!==null){
          result.children=[];
          item.aggs.forEach((subItem)=>{
            result.children.push(makeItem(subItem));
          })
        }
        if(result.children) {
          if (this.chartType === CHART_TYPES.PIE) {
            if (result.children.length === 2) {
              if (result.children[0].value == null) {
                result.value = result.children[1].value;
                result.children = result.children[0].children;

              } else if (result.children[1].value == null) {
                result.value = result.children[0].value;
                result.children = result.children[1].children;
              }
            } else if (result.children.length == 1) {
              if (result.children[0].value == null) {
                result.children = result.children[0].children;
              } else if (result.children[0].name.indexOf(param.configuration.data.metrics[0].aggregation) >= 0) {
                result.value = result.children[0].value;
                result.children = null;
              }
            }
            // if (result.children.length == 1 && result.children[0].value == null) {
            //   result.children = result.children[0].children;
            // }else if(result.children.length == 2 && result.children[0].value == null){
            //   result.value = result.children[1].value;
            //   result.children = result.children[0].children;
            // }


          }
        }
        console.log(result)
        return result;
      }


      if(res.data.code == 0){
        colorLog.red(res.data)
        if(res.data.code == 0) {
          let result = res.data.data;
          if (this.chartType === CHART_TYPES.PIE) {

            let chartData = []

            result.forEach((item) => {
              chartData.push(makeItem(item))

            })


            console.log(chartData)
            this.chartData = chartData;
          }else if(this.chartType===CHART_TYPES.TABLE){
            this.chartData={}
            this.chartData.chartProps=[];
            let table= {
              column:[],
              data:[]
            };
            let i = 0;
            this.bucketsConfigs.forEach((bucket)=>{

              table.column.push({
                label:bucket.aggregation=='条款'?bucket.field+":"+bucket.orderSort.order:bucket.aggregation=='过滤器'?"过滤器":"日期",
                prop:`fields[${i++}]`
              })
            })
            this.metricsConfigs.forEach((metric)=>{
              table.column.push({
                label:metric.field+metric.aggregation,
                prop:`fields[${i++}]`
              })
            })
            result.forEach((item)=>{

              this.getTableData(table,item,1,[])
            })
            this.chartData.chartProps = table.column;
            this.chartData.data = table.data.map((column)=>{
              return {fields:column}
            });
            colorLog.red(this.chartData)

          }else if(this.chartType===CHART_TYPES.LINE){
            this.chartData = [];
            this.currentFilterCount=0;
            this.xAxis.data=[]
            let map = {};
            result.forEach((item)=>{

              let name = ""
              if(isNaN(Date.parse(item.name))){
                name= item.name;
              }else{
                let date = new Date(item.name)
                name= item.name= date.toLocaleString();
              }

              this.xAxis.data.push(name)
              this.getData(map,item,name);

            })
            let needAllCount = true;
            if(this.metricsConfigs.find((item)=>item.aggregation==='总数')==null ){
              needAllCount=false;
            }
            Object.entries(map).forEach(item=>{
              let matchMetric = false;
              this.metricsConfigs.forEach(metric=>{
                if((item[0].endsWith(metric.aggregation )&& item[0]!=='总数') || (needAllCount && item[0].startsWith('总数')&&item[0]!=='总数')){
                  matchMetric=true;
                }
              })

              if( matchMetric ||(needAllCount && this.bucketsConfigs.length===1)) {
                let line = {name: item[0], type: "line", data: []}
                this.legend.data.push(item[0]);
                this.xAxis.data.forEach((x) => {
                  let value = item[1][x]==null?0:item[1][x];
                  line.data.push(value)
                })
                this.chartData.push(line);
              }
            })


            console.log(this.chartData);
          }else if(this.chartType===CHART_TYPES.BAR){
            this.chartData = [];
            this.currentFilterCount=0;
            this.xAxis.data=[]
            let map = {};
            result.forEach((item)=>{

              let name = ""
              if(isNaN(Date.parse(item.name))){
                name= item.name;
              }else{
                let date = new Date(item.name)
                name= item.name= date.toLocaleString();
              }

              this.xAxis.data.push(name)
              this.getData(map,item,name);

            })
            let needAllCount = true;
            if(this.metricsConfigs.find((item)=>item.aggregation==='总数')==null ){
              needAllCount=false;
            }
            Object.entries(map).forEach(item=>{
              let matchMetric = false;
              this.metricsConfigs.forEach(metric=>{
                if((item[0].endsWith(metric.aggregation )&& item[0]!=='总数') || (needAllCount && item[0].startsWith('总数')&&item[0]!=='总数')){
                  matchMetric=true;
                }
              })
              if(matchMetric ||(needAllCount && this.bucketsConfigs.length===1)) {
                let bar = {name: item[0], type: "bar", data: []}
                this.legend.data.push(item[0]);
                if (item[0].indexOf("-") >= 0) {
                  bar.stack = item[0].substring(0, item[0].lastIndexOf("-"))
                }
                this.xAxis.data.forEach((x) => {
                  bar.data.push(item[1][x])
                })
                this.chartData.push(bar);
              }
            })


            console.log(this.chartData);
          }

        }
      }



    },
    getData(map,item,xAxis,parent){
      if(item.count!=null){
        item.value=item.count;
      }
      if(item.value=='-Infinity'){
        item.value=0;
      }
      if(parent==null)parent="";
      let name = item.name==xAxis?"总数":parent+"-"+item.name;
      if(item.value!=null){
        if(map[name]==null){
          map[name]={};
        }
        map[name][xAxis]=item.value;
      }else{
        if(item.name=='过滤器'){
          let filters= this.bucketsConfigs.filter((item)=>item.aggregation=='过滤器')
          console.log(filters);
          let filter = filters[this.currentFilterCount];
          console.log(filter);
          if(item.aggs){
            let i = 0;
            item.aggs.forEach((subItem)=>{
              subItem.name=filter.filters[i++].value;

            })
          }
        }
      }

      if(item.aggs){
        item.aggs.forEach((subItem)=>{
          this.getData(map,subItem,xAxis,name);
        })
      }
      if(item.percentiles){
        item.percentiles.forEach((percent)=>{
          if(map['percent:'+percent.value]==null){
            map['percent:'+percent.value]={}
          }
          map['percent:'+percent.value][xAxis]=percent.percent;
        })
      }

    },
    getTableData(table,item,index,column){

      let newColumn = deepClone(column);
      if(item.count){
        newColumn.push(item.name);
        if(index===1&&item.aggs==null){
          newColumn.push(item.count)
        }
      }
      else if(item.value){
        newColumn.push(item.value);
      }
      if((index==table.column.length && newColumn.length==index)||newColumn.length==table.column.length){
        table.data.push(newColumn)
      }else{

        if(item.aggs){
          item.aggs.forEach((subItem)=>{
              if(!item.count&&!item.value){
                this.getTableData(table,subItem,index,newColumn)
              }else{
                this.getTableData(table,subItem,index+1,newColumn)
              }
          })
        }
      }


    },

    async loadChartFields(){
      const res = await getElasticTableInfo('clm-metago-contract-fact');
      if(res.data.code == 0){

        this.filterSettingFields = res.data.data.fields
      }
    },

    /**
     * 后端传来的数据转换为chartJs可用的数据
     */
    transformData(data){
      let transformedData = {};
    },

    timeRangeChanged(){
      this.checkTimeRange();
      if(this.timeRange){
        this._timeRange={start:this.timeRange[0],end:this.timeRange[1]}
      }else{
        this._timeRange = null;
      }
    }
  },
  created(){
    this.loadChartFields();
    //初始化左侧控制面板的配置
    this.initializeConfigs();
    this.backupConfigs();
  },
  beforeUpdate() {
    this.settingVnodes = [];
  },
  mounted() {
    colorLog.red('expose data', {
      metrics:this.metricsConfigs,
      bucketsConfigs:this.bucketsConfigs
    });

  }
}
</script>
<style>
body{
  overflow: hidden;
}
</style>
<style scoped>
/*面向属性*/
.truncate{

}
.statistic-container >>> .color-error{
  color:var(--el-color-error);
}
.statistic-container >>> .color-error-hover{
  transition: .4s color;
}
.statistic-container >>> .color-error-hover:not(.disabled):hover{
  color:var(--el-color-error);
}
.statistic-date-picker.empty >>> .el-date-editor{
  border-color: var(--el-color-error);
}
.statistic-container >>> .color-blue-hover{
  transition: .4s color;
}
.statistic-container >>> .color-blue-hover:not(.disabled):not(.last):hover{
  color:var(--lightBlue);
}

.statistic-container{
  width: 100%;
  height:100%;
  display:grid;
  grid-template: "t t t" minmax(2rem,auto)
                 "t2 t2 t2" auto
                 "t3 t3 t3" minmax(calc(2rem + 10px),auto)
                 "b b b" 1fr /
                  1fr 2fr 2fr
;
}
.statistic-top-bar{
  grid-area: t;
}
.statistic-date-picker{
  grid-row: t2;
  grid-column: 3 / span 1;
}
.statistic-filter-bar{
  grid-area: t3;
  z-index:1;
}
.statistic-main{
  grid-area: b;
  display: flex;
  min-height: 0;
}
.sm-lcp-sa-panel{
  margin: .5rem;
  border: 1px solid var(--gray-2);
  box-shadow: 0 0 3px var(--gray-2);
  border-radius: 4px;
  background: white;
}
.sm-left-control-panel{
  flex:0 0 380px;
  background:var(--page-gray);
  position: relative;
  flex-flow: column;
  z-index: 1;
  overflow: hidden;
}
.sm-lcp-sample-set-title{
  height: 3rem;
  background: var(--darkBlue);
  color:white;
  box-sizing: border-box;
  font-size: 0;
  text-align: center;
  padding: 5px;
}
.sm-lcp-sample-set-title::before{
  content:'';
  width: 0;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.sm-lcp-sample-set-title-wrap{
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  font-size: 1.25rem;
}
.sm-lcp-panel-setting-bar{
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.sm-right-chart-view{
  flex:1;
  position:relative;
  overflow:auto;
  text-align: center;
  white-space: nowrap;
  padding: 0 0 0 10px;
  background: white;
}
.sm-right-chart-view > div[class*=statistic-]{
  vertical-align: middle;
  height: fit-content;
  display: inline-block;
}
.sm-right-chart-view::before{
  content:'';
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: middle;
}
.sa-ps-input-area{
  text-align: left;
  padding:5px;
}
.sm-lcp-setting-area{
  overflow: auto;
  scroll-behavior: smooth;
  top: 6rem;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  min-height: 0;
}
.sm-lcp-sa-panel-title{
  text-align: left;
  padding:0 20px;
}
.sm-left-control-panel-minimize-bar{
  background: var(--page-gray);
}
.sm-left-control-panel-minimize-btn{
  cursor: pointer;
  padding: 2px;
  margin: 4px;
  clip-path: circle();
  background: var(--font-black);
  color:white;
  opacity: .4;
}
.sm-left-control-panel-minimize-btn:hover{
  opacity: 1;
}

.minimize-arrow{
  font-size: 12px;
  font-weight: bolder;
  transform: rotate(-90deg) translateY(-4.5%) translateX(5%);
}
.minimize-arrow--minimized{
  transform: rotate(90deg) translateY(-4.5%) translateX(0%);
}

.sm-outer-add{
  margin-bottom: 10px;
}
.sm-panel-tool{
  padding: calc((2rem - 16px) / 2);
  transition:.2s;
  position: relative;
}
.sm-panel-disable.disabled::after{
  content: '';
  width: 0.75em;
  margin-left: -0.875em;
  margin-top: 0.5em;
  border-bottom: 1px solid;
  position: absolute;
  transform: rotate(45deg)
}
.sm-panel-disable.last{
  color:var(--gray-2);
}
</style>
