<template>
  <div class="add-filter-unit">
    <span class="afu-icon el-icon-circle-plus-outline"></span>
    <span class="afu-text">添加过滤器</span>
  </div>
</template>

<script>
export default {
  name: "AddFilterUnit"
}
</script>

<style scoped>
.add-filter-unit{
  text-align: center;
  position: relative;
  margin-top: 10px;
  padding: .5em 0;
  cursor: pointer;
  transition: .2s color;
  background: var(--lightBlue);
  border-radius: 4px;
  color:white;
  transition: .2s;
}
.afu-icon{
  position: absolute;
  top:calc(50% - .4em);
  margin-left: -20px;
}
.add-filter-unit:hover{
  box-shadow: inset 0 -10px 20px 0px rgb(0 0 0 / 20%);
}
</style>
