<template>
  <div class="statistic-toolbar">
    <ul class="st-ul">
      <li class="st-ul-li" @click="handleSaveBtnClick">保存</li>
      <li class="st-ul-li" @click="handleShareBtnClick">分享</li>
      <li class="st-ul-li" @click="handleRefreshBtnClick">刷新</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StatisticToolbar",
  methods:{
    handleSaveBtnClick(){
      this.$emit('save')
    },
    handleShareBtnClick(){
      this.$emit('share')
    },
    handleRefreshBtnClick(){
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>
.statistic-toolbar{
  height: 100%;
}
.st-ul{
  display:flex;
  padding:0;
  list-style: none;
  margin:0;
  height: 100%;
  align-items: center;
}
.st-ul-li{
  cursor:pointer;
  color:var(--lightBlue);
  margin: 0 1ch;
  font-size: 1rem;
}
.st-ul-li:hover{
  text-decoration: underline;
}

</style>
