<template>
<div class="add-option-unit">
    <span class="ad-unit-icon el-icon-circle-plus"></span>
    <span class="ad-unit-tip" @click="$emit('add')">添加{{tip}}</span>
</div>
</template>

<script>
export default {
  name: "AddOptionUnit",
  props:{
    tip:{
      required:false,
      default:'项'
    }
  },
  methods:{
  }
}
</script>

<style scoped>
.add-option-unit{
  text-align: center;
  position: relative;
  margin-top: 10px;
  padding: 5px;
  transition: .2s color;
  color:var(--lightBlue);

}
.ad-unit-tip{
  cursor: pointer;
  position: relative;
}
.ad-unit-tip::before{
  content:'';
  position: absolute;
  left: -2em;
  right: 0;
  top: 0;
  bottom:0;
}
.ad-unit-tip:hover{
  text-decoration: underline;
}
.ad-unit-icon{
  position: absolute;
  top:calc(50% - .6em);
  margin-left: -20px;
}

</style>
