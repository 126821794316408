import {
    AGGREGATION_FILTER_OPERATORS, AGGREGATIONS,
    FIELD_TYPES,
    FILTER_CONVERSE_OPERATOR,
    FILTER_OPERATORS,
    FILTER_VALUE_PROPS, SETTING_ITEM_OPTIONS, TIME_INTERVAL
} from "./statisticChartConfig";
import {colorLog, deepClone} from "../../../util";

export function isNotNull(o){
    let flag = false;
    switch (typeof o){
        case 'number':
            flag = true;
            break;
        case 'string':
            flag = o != '';
            break;
        case 'object':
            flag = (o != null && Object.keys(o).length != 0) || o instanceof Date
    }

    return flag;
}

function isAllComplete(o){
    return isNotNull(o.start) && isNotNull(o.end);
}

export function filterValueValidate(data,fieldType,operator){
    if(fieldType != FIELD_TYPES.DATE){
        switch (operator){
            case FILTER_OPERATORS.IS:
            case FILTER_OPERATORS.IS_NOT:
                return isNotNull(data[FILTER_VALUE_PROPS.CERTAIN]);
            case FILTER_OPERATORS.IS_ONE_OF:
            case FILTER_OPERATORS.IS_NOT_ONE_OF:
                return isNotNull(data[FILTER_VALUE_PROPS.MULTI]);
            case FILTER_OPERATORS.IN_RANGE:
            case FILTER_OPERATORS.OUT_OF_RANGE:
                return isAllComplete(data[FILTER_VALUE_PROPS.VALUE_RANGE]);
        }
    }else{
        switch (operator){
            case FILTER_OPERATORS.BEFORE:
                return isNotNull(data[FILTER_VALUE_PROPS.TIME_RANGE].end);
            case FILTER_OPERATORS.AFTER:
                return isNotNull(data[FILTER_VALUE_PROPS.TIME_RANGE].start);
            case FILTER_OPERATORS.IN_RANGE:
            case FILTER_OPERATORS.OUT_OF_RANGE:
                return isAllComplete(data[FILTER_VALUE_PROPS.TIME_RANGE]);
        }
    }
}

export function isFilterOperatorSimilar(operator1,operator2){
    return FILTER_CONVERSE_OPERATOR[operator1] === operator2;
}

export function getFieldType(filterSettingFields,field){
    if(!filterSettingFields) return null;
    const found = filterSettingFields.find(item => {
        return item.fields.includes(field);
    });
    if(!found) return null
    return found.type;
}

export function filterParam(param) {
    param.filters = param.filters.filter(filter => !filter.disabled).map(
        filter => filter.filter);
}

export function processTimeRange(filter,processors){
    function callProcessor (processor,val){
       return processor && processor(val);
    }
    switch (filter.operator){
        case AGGREGATION_FILTER_OPERATORS.AFTER:
            return callProcessor(processors[AGGREGATION_FILTER_OPERATORS.AFTER],filter[FILTER_VALUE_PROPS.TIME_RANGE].start);
        case AGGREGATION_FILTER_OPERATORS.BEFORE:
            return callProcessor(processors[AGGREGATION_FILTER_OPERATORS.BEFORE],filter[FILTER_VALUE_PROPS.TIME_RANGE].end);
        case AGGREGATION_FILTER_OPERATORS.IN_RANGE:
            return callProcessor(processors[AGGREGATION_FILTER_OPERATORS.IN_RANGE],filter[FILTER_VALUE_PROPS.TIME_RANGE]);
        case AGGREGATION_FILTER_OPERATORS.OUT_OF_RANGE:
            return callProcessor(processors[AGGREGATION_FILTER_OPERATORS.OUT_OF_RANGE],filter[FILTER_VALUE_PROPS.TIME_RANGE]);
    }
}

export function checkTimeRangeValid(filter){
    let processors = {};
    processors[AGGREGATION_FILTER_OPERATORS.AFTER] =  (start) => {return !!start};
    processors[AGGREGATION_FILTER_OPERATORS.BEFORE] =  (end) => {return !!end};
    processors[AGGREGATION_FILTER_OPERATORS.IN_RANGE] =  (range) => {return !!range.start && !!range.end};
    processors[AGGREGATION_FILTER_OPERATORS.OUT_OF_RANGE] =  (range) => {return !!range.start && !!range.end};
    return processTimeRange(filter, processors);
}

//储存不同聚合在最小化时显示的简短介绍的生成方法
export const aggregationBriefIntroGenerators = {
    [AGGREGATIONS.FILTER]:(config,filterSettingFields)=>{
        let filters = config[SETTING_ITEM_OPTIONS.FIlTERS];
        let oneFilter = filters.find(filter => filter.field);
        let fieldType = getFieldType(filterSettingFields,oneFilter.field);
        let str = `${oneFilter.field}${oneFilter.operator}：`
        if(fieldType == FIELD_TYPES.DATE){
            let processors = {
                [AGGREGATION_FILTER_OPERATORS.AFTER]:(start)=>{ str += `${start}` },
                [AGGREGATION_FILTER_OPERATORS.BEFORE]:(end)=>{ str += `${end}` },
                [AGGREGATION_FILTER_OPERATORS.IN_RANGE]:({start,end})=>{ str += `${start}到${end}` },
                [AGGREGATION_FILTER_OPERATORS.OUT_OF_RANGE]:({start,end})=>{ str += `${start}到${end}` },
            }
            processTimeRange(oneFilter,processors);
        }else{
            str += `${oneFilter.value}`;
        }

        if(filters.length > 1){
            str += '...';
        }
        return str;
    },
    [AGGREGATIONS.DATE]:(config)=>{
        let interval = config[SETTING_ITEM_OPTIONS.MINIMUM_INTERVAL];
        let field = config[SETTING_ITEM_OPTIONS.FIELD];
        let str = `${field}每${interval}`;
        if(interval == TIME_INTERVAL.AUTO){
            str = `${field} ${interval}分隔`;
        }
        return str;
    },
}

//储存不同输入信息模组对应的检查数据是否合法的方法
export const configInvalidCheckers = {
    [SETTING_ITEM_OPTIONS.FIlTERS]:(filters,filterSettingFields)=>{
        let flag = true;
        filters.forEach((filter)=>{
            if(!flag) return;
            flag = Object.keys(filter).every(kn => {
                if(kn === 'label' || kn === 'id') return true;
                let fieldType = getFieldType(filterSettingFields,filter.field);
                if(fieldType == FIELD_TYPES.DATE){
                    return checkTimeRangeValid(filter);
                }
                return filter[kn];
            })
        })
        return flag;
    },
    [SETTING_ITEM_OPTIONS.VALUES]:(values)=>{
       if(!values) return false;
       return values.length != 0 && values.every(val => isNotNull(val));
    },
    [SETTING_ITEM_OPTIONS.PERCENTS]:(percents)=>{
        if(!percents) return false;
        return percents.length != 0 && percents.every(val => isNotNull(val));
    },
    [SETTING_ITEM_OPTIONS.ORDER_SORT]:(obj)=>{
        return isNotNull(obj.order) && isNotNull(obj.size) && (typeof parseInt(obj.size) == 'number');
    }
}
