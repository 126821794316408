<template>
  <div class="statistic-setting-item">
    <input :id="proxyId"
           class="setting-checkbox-proxy"
           type="checkbox"
           :checked="expanded"
           @change="handleExpandedToggle">
    <div class="sa-ps-type">
      <label :for="proxyId" class="sa-ps-label">
        <span class="el-expandArrow-wrap">
          <i class="el-icon-arrow-up sa-expandArrow"
             ref="expandArrow"
             :class="{'sa-expandArrow--expand':expanded}"
          />
        </span>

          <div class="sa-spmt">
          <span class="sa-name">
          {{title}}
          <span class="gray sa-name-brief">
            {{saBrief}}
          </span>
        </span>
          </div>
      </label>
      <el-tooltip
          show-after="500"
          hide-after="0"
          v-if="inputsInvalid && !expanded"
          content="请填充所需信息"
      >
        <span class="sa-spmt-warning el-icon-warning"></span>
      </el-tooltip>
        <div class="sa-spmt-tools">
          <slot name="tools"></slot>
        </div>
    </div>

    <div class="sa-ps-input-area" ref="inputArea">
      <AggregationOption
          class="sa-ps-input-item"
          :aggregations="aggregations"
          v-model:value="config.aggregation"
      ></AggregationOption>
      <FieldOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.FIELD)"
          class="sa-ps-input-item"
          v-model:value="config[SETTING_ITEM_OPTIONS.FIELD]"
          :fields="fields"
          :aggregation="config.aggregation"
      ></FieldOption>
      <valueOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.VALUES)"
          :ref="pushInputOptionVnodes"
          class="sa-ps-input-item"
          v-model:values="config[SETTING_ITEM_OPTIONS.VALUES]"
          :aggregation="config.aggregation"
      ></valueOption>
      <PercentileOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.PERCENTS)"
          :ref="pushInputOptionVnodes"
          class="sa-ps-input-item"
          v-model:values="config[SETTING_ITEM_OPTIONS.PERCENTS]"
          :aggregation="config.aggregation"
      ></PercentileOption>
      <IntervalOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.MINIMUM_INTERVAL)"
          class="sa-ps-input-item"
          v-model:value="config[SETTING_ITEM_OPTIONS.MINIMUM_INTERVAL]"
          :aggregation="config.aggregation"
      ></IntervalOption>

      <FilterGroup
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.FIlTERS)"
          v-model:filters="config[SETTING_ITEM_OPTIONS.FIlTERS]"
          :fields="fields"
          :aggregation="config.aggregation"
      >
      </FilterGroup>
      <OrderByOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.ORDER_BY)"

          class="sa-ps-input-item"
          v-model:orderByInfo="config[SETTING_ITEM_OPTIONS.ORDER_BY]"
          :aggregation="config.aggregation"
          :metrics-configs="metricsConfigs"
      ></OrderByOption>
      <OrderSortOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.ORDER_BY)"

          class="sa-ps-input-item"
          :order-sort="config[SETTING_ITEM_OPTIONS.ORDER_SORT]"
      ></OrderSortOption>
      <CustomLabelOption
          v-if="isDisplayable(SETTING_ITEM_OPTIONS.CUSTOM_LABEL)"
          class="sa-ps-input-item"
          v-model:value="config[SETTING_ITEM_OPTIONS.CUSTOM_LABEL]"
          :aggregation="config.aggregation"
      ></CustomLabelOption>
    </div>

  </div>
</template>

<script>
import SvgIcon from "../SvgIcon/svgIcon";
import {BriefEmitter, colorLog, deepClone, getUniqueId, singleListenerManager} from "../../util";
import {controlPanelOptionTree, SETTING_ITEM_OPTIONS, OPTIONAL_OPTIONS, CHART_EVENTS} from "./js/statisticChartConfig";
import {aggregationBriefIntroGenerators,configInvalidCheckers} from "./js/chartUtil";
import AggregationOption from "./options/AggregationOption";
import FieldOption from "./options/FieldOption";
import ValueOption from "./options/ValueOption";
import CustomLabelOption from "./options/CustomLabelOption";
import PercentileOption from "./options/PercentileOption";
import IntervalOption from "./options/IntervalOption";
import FilterGroup from "./options/FilterGroup";
import OrderByOption from "./options/OrderByOption";
import OrderSortOption from "./options/OrderSortOption";

export default {
  name: "StatisticSettingItem",
  components: {
    OrderSortOption,
    OrderByOption,
    FilterGroup,
    IntervalOption, PercentileOption, CustomLabelOption, ValueOption, FieldOption, AggregationOption, SvgIcon},
  props:{
    config:{
      required:true
    },
    aggregations:{
      required:true
    },
    fields:{
      required:true
    },
    title:{
      required:true
    },
    metricsConfigs:{
      required:false
    }
  },
  data(){
    return {
      SETTING_ITEM_OPTIONS,
      expanded:true,
      proxyId:getUniqueId('settingItem'),
      updateHandler:null,
      inputOptionVnodes:[],
    }
  },
  watch:{
    'config.aggregation':{
      deep:true,
      handler:function(val){
        this.$nextTick(()=>{
          this.expandInputArea();
        })
      }
    },
    ['config.' + SETTING_ITEM_OPTIONS.VALUES]:{
      deep:true,
      handler:function(val){
        this.$nextTick(()=>{
          this.expandInputArea();
        })
      }
    },
    ['config.' + SETTING_ITEM_OPTIONS.PERCENTS]:{
      deep:true,
      handler:function(val){
        this.$nextTick(()=>{
          this.expandInputArea();
        })
      }
    },
    ['config.' + SETTING_ITEM_OPTIONS.FIlTERS]:{
      deep:true,
      handler:function(val){
        this.$nextTick(()=>{
          this.expandInputArea();
        })
      }
    }
  },
  computed:{
    saBrief(){
      if(this.config.aggregation && !this.expanded && !this.inputsInvalid){
        if(aggregationBriefIntroGenerators[this.config.aggregation]){
          return aggregationBriefIntroGenerators[this.config.aggregation](this.config,this.fields)
        }
        return (this.structure.includes(SETTING_ITEM_OPTIONS.FIELD) && this.config.field ?
            this.config.field + '的' : '') +
            this.config.aggregation
      }
      return '';
    },
    structure(){
      return controlPanelOptionTree[this.config.aggregation];
    },
    inputsInvalid(){
      if(!this.structure) return !this.config.aggregation;
      let flag = this.structure.every(keyName =>{
        const value = this.config[keyName];

        if(OPTIONAL_OPTIONS.includes(keyName)) return true;
        if(configInvalidCheckers[keyName]){
          return configInvalidCheckers[keyName](value,this.fields);
        }

        return value;
      })

      return !flag || !this.config.aggregation;
    }
  },
  methods:{
    isDisplayable(option){
      if(!this.structure) return false;
      return this.structure.includes(option);
    },
    pushInputOptionVnodes(vn){
      this.inputOptionVnodes.push(vn);
    },
    handleExpandedToggle(e){
      this.expanded = e.target.checked;
      if(this.expanded){
        //展开配置框
        this.expandInputArea();
      }else{
        this.minimizeInputArea();
      }
    },
    expandInputArea(){
      const inputArea = this.$refs.inputArea;
      this.expanded = true;
      //拿到里面所以的子选项,求它们的高度总和
      let children = inputArea.querySelectorAll('.sa-ps-input-item');
      const childrenHeight = [...children].reduce((totalHeight,child,index)=>{
        if(index != 0){
          totalHeight += 10; // 10 是margin垂直方向的值
        }
        totalHeight += child.offsetHeight;
        return totalHeight;
      },0)
      inputArea.style.setProperty('height',childrenHeight + 'px');
    },
    minimizeInputArea(){
      const inputArea = this.$refs.inputArea;
      inputArea.style.setProperty('height',0);
    },

    getSettingItemValidation(){
      let allValueInputValid = true;

      if(this.inputOptionVnodes.length > 0){
        allValueInputValid = this.inputOptionVnodes.every(vnode => {
          colorLog.$1('getSettingItemValidation',vnode.getNumberInputValidations(),vnode.getNumberInputValidations().every(v=>v));
          return vnode.getNumberInputValidations().every(v=>v);
        })
      }

      return !this.inputsInvalid && allValueInputValid;
    }
  },
  created(){
    this.updateHandler = ()=>{
      if(this.expanded){
        this.expandInputArea();
      }
    }
    singleListenerManager.on(CHART_EVENTS.EXPAND_INPUT_AREA,this.updateHandler);
  },
  beforeUpdate() {
    this.inputOptionVnodes = [];
  },
  beforeUnmount() {
    singleListenerManager.off(CHART_EVENTS.EXPAND_INPUT_AREA,this.updateHandler);
  },
  mounted() {
    if(this.expanded) this.expandInputArea();
  }
}
</script>
<style scoped>
.sa-ps-input-item >>> .el-input__inner[type=number]{
  line-height: 20px;
}
.sa-ps-input-item >>> .sa-ps-ia-input .el-select{
  display: block;
}
</style>
<style scoped lang="scss">
@import './scss/settingItem';
</style>
