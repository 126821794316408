<template>
  <div class="sm-left-control-panel-resize"
       :class="{'sm-left-control-panel-resize--active':isActive}"
       @mousedown="handleResizeTap"
  >

  </div>
</template>

<script>
import {colorLog, DomEventListenerManager,singleListenerManager} from "../../util";
import {CHART_EVENTS} from "./js/statisticChartConfig";

export default {
  name: "StatisticControlPanelResize",
  props:{
    resizeTarget:{
      required:true
    }
  },
  data(){
    return {
      isActive:false,

      minWidth:280,
      maxWidth:1000,

      baseWidth:0,
      offset:0,
      baseX:0,
      targetDom:null,
      domEventListenerManager:new DomEventListenerManager(),
    }
  },
  methods:{
    handleResizeTap(e){
      this.isActive = true;
      this.baseX = e.pageX;
      this.targetDom = this.resizeTarget();
      this.baseWidth = this.targetDom.clientWidth;
    },
    handleGlobalMouseUp(e){
      if(this.isActive){
        //广播让控制面板调整高度
        singleListenerManager.emit(CHART_EVENTS.EXPAND_INPUT_AREA);
      }
      this.isActive = false;
      this.offset = 0;
    },
    handleGlobalMouseMove(e){
      if(!this.isActive) return;
      window.getSelection().empty();
      this.offset = e.pageX - this.baseX;
      let expectWidth = this.baseWidth + this.offset;
      if( this.minWidth <= expectWidth && expectWidth <= this.maxWidth){
        this.targetDom.style.setProperty('flex-basis', expectWidth + 'px');
      }
    }
  },
  mounted() {
    this.domEventListenerManager.registerListener(document,'mouseup',this.handleGlobalMouseUp.bind(this),{capture:true});
    this.domEventListenerManager.registerListener(document,'mousemove',this.handleGlobalMouseMove.bind(this),{capture:true});
  },
  unmounted() {
    this.domEventListenerManager.removeListener();
    singleListenerManager.off(CHART_EVENTS.EXPAND_INPUT_AREA);
  }
}
</script>

<style scoped>
.sm-left-control-panel-resize{
  /*top: 0;*/
  /*right: -12px;*/
  /*position: absolute;*/
  background: var(--page-gray);
  height: 100%;
  width: 12px;
  cursor: ew-resize;
}
.sm-left-control-panel-resize:hover:not([class*=--active]){
  background: var(--hoverLightBlue);
}
.sm-left-control-panel-resize::before{
  content:'';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
  user-select: none;
}
.sm-left-control-panel-resize::after{
  content:'. . .';
  font-size: 16px;
  line-height: .4;
  color:var(--font-gray);
  font-weight: bolder;
  width: min-content;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  display: inline-block;
}
.sm-left-control-panel-resize--active{
  background: var(--lightBlue);
}
.sm-left-control-panel-resize--active::after{
  color:white !important;
}
</style>
