<template>
  <div class="percentile-option">
    <label class="sa-ps-ia-title" :class="{valid:valueValidated}">Percentile</label>
    <template v-if="listVisible">
      <NumberInputUnit
          :ref="pushVnode"
          v-for="(obj,index) in valuesTemp"
          v-model:value="valuesTemp[index]"
          :key="index"
          :index="index"
          :disabled="valuesTemp.length == 1"
          :validations="validations"
          @delete="handleDeleteBtnClicked(index)"
          @changeValue="handleInputChangeValue"
      ></NumberInputUnit>
    </template>

    <addOptionUnit @add="handleAddOptionClicked">
    </addOptionUnit>
  </div>
</template>

<script>
import {valueOptionMixin} from "./mixins";
import {difference} from 'lodash';
import {controlPanelOptionTree, SETTING_ITEM_OPTIONS} from "../js/statisticChartConfig";
import {colorLog, getUniqueId, singleListenerManager} from "../../../util";
import {toRaw} from "vue";

export default {
  name: "PercentileOption",
  data(){
    return {
      defaultValues:[1,5,25,50,75,95,99],
      validations:[
        {
          handler:(val) => {
            if(val > 100 || val < 0){
              return false;
            }else{
              return true;
            }
          },
          tip:'数值不能超过100或小于0'
        },
        {
          handler:(val,id) => {
            let duplicate = this.valuesTemp.filter(_val => _val == val).length > 1;
            if(duplicate){
              return false;
            }
            return true;
          },
          tip:'数值重复'
        },
      ]
    }
  },
  mixins:[valueOptionMixin],
  computed:{
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
<style scoped lang="scss">
@import "../scss/option";
</style>
