<template>
  <div class="customLabel-option" v-if="displayable">
    <label class="sa-ps-ia-title">自定义标签</label>
    <div class="sa-ps-ia-input">
      <el-input v-model="valueTemp"
                clearable
                placeholder="选填"
                @change="handleInputChange"/>
    </div>
  </div>
</template>

<script>
import {controlPanelOptionTree, SETTING_ITEM_OPTIONS} from "../js/statisticChartConfig";

export default {
  name: "CustomLabelOption",
  props:{
    value:{
      required:false
    },
    aggregation:{
      required:true
    }
  },
  data(){
    return {
      valueTemp:null,
    }
  },
  watch:{
    value:{
      handler:function(val){
        this.valueTemp = val;
      },
      immediate:true
    }
  },
  computed:{
    displayable(){
      return controlPanelOptionTree[this.aggregation]?.includes(SETTING_ITEM_OPTIONS.CUSTOM_LABEL);
    }
  },
  methods:{
    handleInputChange(val){
      this.$emit('update:value',val);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/option";
</style>
