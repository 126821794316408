<template>
  <div class="content-top-bar-close"
  :style="{
    '--offsetY':offsetY,
    '--offsetX':offsetX
  }">
    <svg-icon name="close" width="100%" height="100%"></svg-icon>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon/svgIcon";
export default {
  name: "DialogCloseBtn",
  components: {SvgIcon},
  props:{
    offsetY:{
      required:false,
      default:'0px'
    },
    offsetX:{
      required:false,
      default:'0px'
    }
  }
}
</script>

<style scoped>
.content-top-bar-close::before{
  position: absolute;
  content:'';
  left: -10px;
  right: -10px;
  top:-10px;
  bottom:-10px;
}
.content-top-bar-close{
  --offsetY:0px;
  --offsetX:0px;

  box-sizing: border-box;
  margin: 0 0 0 auto;
  height: 1rem;
  width:1rem;
  top:  calc(.5rem + var(--offsetY,0px));
  right: calc(.5rem + var(--offsetX,0px));
  position: absolute;
  color:var(--font-gray);
}
.content-top-bar-close:hover{
  color:var(--lightBlue);
}
.content-top-bar-close >>> .svg-icon{
  vertical-align: top;
}
</style>
