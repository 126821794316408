<template>
  <div class="sa-ps-input-item">
    <label class="sa-ps-ia-title">聚合</label>
    <div class="sa-ps-ia-input">
      <OptionSelect v-model="valueTemp"
                    @change="handleSelectionChanged"
                    placeholder="请选择统计量"
      >
        <el-option
            v-for="item in aggregations"
            :key="item[0]"
            :label="item[1]"
            :value="item[1]"
        />
      </OptionSelect>
    </div>
  </div>
</template>

<script>
import {colorLog, deepClone} from "../../../util";
import OptionSelect from "./common/OptionSelect";
export default {
  name: "AggregationOption",
  components: {OptionSelect},
  props:{
    aggregations:{
      required:true
    },
    value:{
      required:true
    }
  },
  data(){
    return {
      valueTemp:deepClone(this.value),
    }
  },
  watch:{
    value(newVal){
      this.valueTemp = newVal;
    },
  },
  methods:{
    handleSelectionChanged(val){
      this.$emit('update:value',val);
    }
  }
}
</script>
<style scoped lang="scss">
@import "../scss/option";
</style>
