<template>
  <span class="sf-detail"
  v-if="isNormal">
    <span class="filter-item-field">{{ `${filter.field} ` }}</span>
    <span class="filter-item-operator">{{ filter.operator }}</span>
    <span class="filter-item-value">{{ ` ${filter[FILTER_VALUE_PROPS.CERTAIN]}` }}</span>
  </span>
  <span class="sf-detail"
        v-if="isValueOneOf">
    <span class="filter-item-field">{{ `${filter.field} ` }}</span>
    <span class="filter-item-operator">{{ filter.operator }}</span>
    <span class="filter-item-value">{{ ` ${filter[FILTER_VALUE_PROPS.MULTI]}` }}</span>
  </span>

  <span class="sf-detail"
        v-if="isValueRange">
    <span class="filter-item-field">{{ `${filter.field} ` }}</span>
    <span class="filter-item-operator">{{FILTER_EXCLUDE_OPERATOR.includes(filter.operator) ? '不' : ''}}在</span>
    <span class="filter-item-value">{{ ` ${filter[FILTER_VALUE_PROPS.VALUE_RANGE].start} ` }}</span>
    <span class="filter-item-operator">到</span>
    <span class="filter-item-value">{{ ` ${filter[FILTER_VALUE_PROPS.VALUE_RANGE].end}` }}</span>
    <span class="filter-item-operator">之间</span>
  </span>

  <span class="sf-detail"
        v-if="isTimeRange">
    <span class="filter-item-field">{{ `${filter.field} ` }}</span>
    <span class="filter-item-operator">{{FILTER_EXCLUDE_OPERATOR.includes(filter.operator) ? '不' : ''}}在</span>
    <span class="filter-item-value">{{ ` ${formatTime(filter[FILTER_VALUE_PROPS.TIME_RANGE].start)} ` }}</span>
    <span class="filter-item-operator">到</span>
    <span class="filter-item-value">{{ ` ${formatTime(filter[FILTER_VALUE_PROPS.TIME_RANGE].end)}` }}</span>
    <span class="filter-item-operator">之间</span>
  </span>

  <span class="sf-detail"
        v-if="isTimeInter">
    <span class="filter-item-field">{{ `${filter.field} ` }}</span>
    <span class="filter-item-operator">在</span>
    <span class="filter-item-value">{{
        ` ${
            formatTime(FILTER_OPERATORS.BEFORE == filter.operator ?
              filter[FILTER_VALUE_PROPS.TIME_RANGE].end
              : filter[FILTER_VALUE_PROPS.TIME_RANGE].start)
         }`
      }}</span>
    <span class="filter-item-operator">{{filter.operator}}</span>
  </span>
</template>

<script>
import {FIELD_TYPES, FILTER_OPERATORS, FILTER_VALUE_PROPS, FILTER_EXCLUDE_OPERATOR} from "./js/statisticChartConfig";

export default {
  name: "StatisticFilterDetail",
  props:{
    filter:{
      required:true
    },
    fieldType:{
      required: true,
    }
  },
  watch:{

  },
  computed:{
    isNormal(){
      return this.getMode(
          [FIELD_TYPES.STRING,FIELD_TYPES.NUMBER,FIELD_TYPES.DATE],
          [FILTER_OPERATORS.IS,FILTER_OPERATORS.IS_NOT]
      )
    },
    isValueOneOf(){
      return this.getMode(
          [FIELD_TYPES.NUMBER,FIELD_TYPES.STRING],
          [FILTER_OPERATORS.IS_ONE_OF,FILTER_OPERATORS.IS_NOT_ONE_OF]
      )
    },
    isValueRange(){
      return this.getMode(
          [FIELD_TYPES.NUMBER],
          [FILTER_OPERATORS.IN_RANGE,FILTER_OPERATORS.OUT_OF_RANGE]
      )
    },
    isTimeRange(){
      return this.getMode(
          [FIELD_TYPES.DATE],
          [FILTER_OPERATORS.IN_RANGE,FILTER_OPERATORS.OUT_OF_RANGE]
      )
    },
    isTimeInter(){
      return this.getMode(
          [FIELD_TYPES.DATE],
          [FILTER_OPERATORS.AFTER,FILTER_OPERATORS.BEFORE]
      )
    },
  },
  data(){
    return {
      FILTER_OPERATORS,
      FILTER_VALUE_PROPS,
      FILTER_EXCLUDE_OPERATOR,
    }
  },
  methods:{
    getMode(fullFillTypes,fullFillOperators){
      return fullFillTypes.includes(this.fieldType) &&
      fullFillOperators.includes(this.filter.operator);
    },
    formatTime(date){
      return new Date(date).toLocaleString();
    }
  }
}
</script>

<style scoped>

</style>
