<template>
  <div class="statistic-filter-wrap">
    <div class="statistic-filter">
      <el-popover placement="bottom"
                  v-for="(item,index) in filters"
                  @show="showFilterEditing = false"
                  :width="showFilterEditing ? 400 : 150"
                  :title="showFilterEditing ? '编辑过滤':''"
                  :visible="filterPopoversVisible[index]"
                  trigger="manual"
                  :popper-class="'filter-item-popover'"
      >
        <template #reference>
          <el-tag class="filter-item"
                  :class="{'filter-item--not':FILTER_EXCLUDE_OPERATOR.includes(item.filter.operator),
                   'disabled':item.disabled
                  }"
                  size="mini"
                  @close="handleFilterClose(index)"
                  @click="handleFilterClick(item,index)"
                  closable>
            <StatisticFilterDetail
            class="filter-item-wrap"
            :filter="item.filter"
            :field-type="item.fieldType"
            ></StatisticFilterDetail>
          </el-tag>
        </template>
        <template #default>
          <ul class="filter-custom-popover-ul" v-if="!showFilterEditing">
            <li class="filter-custom-popover-li" @click="showFilterEditing = true"><i class="el-icon-edit-outline"></i>编辑</li>
            <li class="filter-custom-popover-li" @click="handleFilterSwitchOperator(index)">
            <span v-if="isIncludeOperatorType(item.filter.operator)">
                <i class="el-icon-remove-outline"></i>转换操作
            </span>
              <span v-else>
              <i class="el-icon-circle-plus-outline"></i>转换操作
            </span>
            </li>
            <li class="filter-custom-popover-li" @click="handleFilterSwitchDisabled(index)">
            <span v-if="!item.disabled">
              <i class="el-icon-turn-off"></i>禁用过滤
            </span>
              <span v-else>
              <i class="el-icon-open"></i>启用过滤
            </span>

            </li>
            <li class="filter-custom-popover-li" @click="handleFilterClose(index)"><i class="el-icon-delete"></i>删除</li>
          </ul>
          <StatisticFilterSetting
              v-else
              :data="item.filter"
              :popover-class="'filter-item'"
              v-model:filterSettingValues="filterSettingValues"
              :filter-setting-fields="filterSettingFields"
              @clearSettingValues="handleClearSettingValues"
              @onCancel="handleFilterEditingCancel($event,index)"
              @onSave="handleFilterEditingSave($event,index)"
          ></StatisticFilterSetting>
        </template>

      </el-popover>

      <el-popover
          popper-class="filter-setting-popover"
          title="添加过滤"
          :width="400"
          trigger="manual"
          v-model:visible="filterSettingVisible"
      >
        <template #reference>
          <button type="default" class="filter-add-item" @click="handleAddLabelClicked">添加过滤</button>
        </template>
        <template #default>
          <StatisticFilterSetting
          :data="filterSettingData"
          v-model:filterSettingValues="filterSettingValues"
          :filter-setting-fields="filterSettingFields"
          ref="filterSetting"
          :popover-class="'filter-setting-popover'"
          @clearSettingValues="handleClearSettingValues"
          @onSave="handleFilterSettingSave"
          @onCancel="handleFilterSettingCancel"
          ></StatisticFilterSetting>
        </template>
      </el-popover>

    </div>

  </div>
</template>

<script>
import {
  FIELD_TYPES,
  FILTER_CONVERSE_OPERATOR,
  FILTER_EXCLUDE_OPERATOR, FILTER_OPERATORS,
  FILTER_OPERATORS_OF_TYPE, FILTER_VALUE_PROPS
} from "./js/statisticChartConfig";
import {colorLog, deepClone,DomEventListenerManager} from "../../util";
import StatisticFilterSetting from "./StatisticFilterSetting";
import {getFieldType} from "./js/chartUtil";
import {getVisualizationTermsData} from "../../api/api";
import {ElMessage} from "element-plus";
import StatisticFilterDetail from "./StatisticFilterDetail";

export default {
  name: "StatisticFilter",
  components: {StatisticFilterDetail, StatisticFilterSetting},
  props:{
    filters:{
      required:false,
      default:[],
    },
    filterSettingFields:{
      required:true,
      default:[]
    }
  },
  data() {
    const defaultFilterSettingData = {
      field: "",
      operator: '',
    }
    return {
      FILTER_EXCLUDE_OPERATOR,
      colorLog,
      filterSettingVisible: false,
      filterSettingValues:[],
      showFilterEditing:false,
      filterPopoversVisible:[],
      defaultFilterSettingData,
      filterSettingData: deepClone(defaultFilterSettingData),
      handleGlobalClick:null,
      domEventListenerManager: new DomEventListenerManager(),
    }
  },
  watch: {
    filterSettingVisible(visible) {
      //关闭'编辑过滤'弹框时清空组件内的数据
      if (!visible) {
        this.clearFilterSettingData();
      }
    },
    'filters.length':{
      handler:function(newVal){
        for(let i = 0;i < newVal;i++){
          this.filterPopoversVisible[i] = false;
        }
      },
      immediate:true
    },
  },
  computed: {

  },
  mounted() {
    this.handleGlobalClick = (e)=>{
      //清除的是标签样式的filter的弹框
      if(!e.target.closest('[class*=filter-item]')){
        this.hideFilterPopover();
      }

      //点击了'编辑过滤'相关的元素清除'添加过滤'的弹框
      if(e.target.closest('[class*=filter-item]')){
        this.filterSettingVisible = false;
      }

      if(e.target.closest('[class*=filter-add-item]')){
        return;
      }
      // if(!e.target.closest('[class*=filter-setting-popover]')){
      //
      // }
    }
    this.domEventListenerManager.registerListener(document,'click',this.handleGlobalClick,{capture:true});
  },
  unmounted() {
    this.domEventListenerManager.removeListener();
  },
  methods: {
    deepClone,
    handleAddLabelClicked() {
      this.filterSettingVisible = !this.filterSettingVisible;
    },
    handleFilterClose(index) {
      this.filters.splice(index, 1);
    },
    handleFilterClick(item, index) {
      this.hideFilterPopover(index);
      this.filterPopoversVisible[index] = !this.filterPopoversVisible[index];
    },
    handleFilterSwitchDisabled(index){
      let field = this.filters[index];
      if(field.disabled){
        field.disabled = false;
      }else{
        field.disabled = true;
      }
    },
    handleFilterSwitchOperator(index){
      let filter = this.filters[index].filter;
      filter.operator = FILTER_CONVERSE_OPERATOR[filter.operator];

      if([FILTER_OPERATORS.BEFORE,FILTER_OPERATORS.AFTER].includes(filter.operator)){
        let temp = filter[FILTER_VALUE_PROPS.TIME_RANGE].start;
        filter[FILTER_VALUE_PROPS.TIME_RANGE].start = filter[FILTER_VALUE_PROPS.TIME_RANGE].end;
        filter[FILTER_VALUE_PROPS.TIME_RANGE].end = temp;
      }
    },
    handleFilterSettingSave(obj){
      this.filters.push(obj)
      this.filterSettingVisible = false;
    },
    handleFilterSettingCancel(obj){
      this.filterSettingVisible = false;
    },
    handleFilterEditingCancel(obj,index){
      this.hideFilterPopover();
      this.$nextTick(()=>{
        this.showFilterEditing = false;
      })
    },
    handleFilterEditingSave(obj,index){
      this.hideFilterPopover();
      this.filters.splice(index,1,obj);
      this.$nextTick(()=>{
        this.showFilterEditing = false;
      })
    },
    handleClearSettingValues(){
      this.clearFilterSettingValues();
    },

    clearFilterSettingData() {
      this.$refs.filterSetting.clearFilter();
    },
    isIncludeOperatorType(operator){
      return !FILTER_EXCLUDE_OPERATOR.includes(operator);
    },
    hideFilterPopover(exclude){
      this.filterPopoversVisible.forEach((item,_index) => {
        if(exclude == _index) return;
        this.filterPopoversVisible[_index] = false
      });
    }

  }
}
</script>

<style scoped>

.statistic-filter-wrap {
  padding: 5px 10px;
}

.statistic-filter {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: -1ch;
  flex-flow: wrap;
  row-gap: 5px;
}



.filter-item {
  color: var(--font-gray) !important;
  border-radius: 0;
  border-color: var(--font-gray) !important;
  margin-inline-end: 1ch;
  background: transparent !important;
}

.el-tag.filter-item {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
}

.el-tag.filter-item:not([class*=disabled]):hover {
  text-decoration: underline;
}
.el-tag.filter-item:hover {
  text-decoration: line-through underline;
}
.filter-item.disabled{
  text-decoration:line-through;
}

.filter-item--not {
  border-color: #ebbebc !important;
}

.filter-item--not >>> .filter-item-operator {
  color: #f56c6c !important;
}

.filter-add-item {
  all: unset;
  font-size: 1rem;
  height: 20px;
  position: relative;
  top: .1ex;
  cursor: pointer;
}

.filter-add-item:hover {
  color: var(--lightBlue);
  text-decoration: underline;
}

.filter-custom-popover-ul {
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
}

.filter-custom-popover-li {
  cursor: pointer;
  padding: 5px;
  min-width: 120px;
}
.filter-custom-popover-li i{
  margin-right: 1ch;
}
.filter-custom-popover-li:hover {
  background: var(--hoverLightBlue);

}
</style>
