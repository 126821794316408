<template>
  <div class="add-config-option-unit">
    <el-popover
        ref="popover"
        trigger="click"
        placement="auto"
        :width="80"
    >
      <template #reference>
        <AddOptionUnit
            @add="handleAddPanelBtnClicked"
            :tip="''"
        ></AddOptionUnit>
      </template>

      <p class="aco-title">添加{{configType}}</p>
      <ul class="aco-options">
        <li class="aco-option"
                   :disabled="CONFIG_TYPE_OPTIONS[chartType][configType][propName].limit <= configs.reduce((sum,config) => { return config.type === propName ? ++sum : sum },0)"
                   @click="(e)=>{ e.currentTarget.getAttribute('disabled') == 'true' ? '' : handleConfigOptionClicked(propName) }"
                   v-for="(propName,index) in Object.getOwnPropertyNames(CONFIG_TYPE_OPTIONS[chartType][configType])">
          {{propName}}
        </li>
      </ul>
    </el-popover>
  </div>
</template>

<script>
import AddOptionUnit from "./AddOptionUnit";
import {CONFIG_TYPE_OPTIONS} from "../../js/statisticChartConfig";

export default {
  name: "AddConfigOptionUnit",
  props:{
    chartType:{
      required:true,
    },
    configType:{
      required:true,
    },
    configs:{
      required:true
    }
  },
  components: {AddOptionUnit},
  data(){
    return {
      CONFIG_TYPE_OPTIONS
    }
  },
  computed:{

  },
  methods:{
    handleAddPanelBtnClicked(){
    },
    handleConfigOptionClicked(propName){
      this.$refs.popover.hide();
      this.$emit('add',this.configType,propName);
    }
  }
}
</script>

<style scoped>
.aco-title{
  font-weight: bold;
  text-align: center;
  margin: 0;
  user-select: none;
  padding-bottom: 1ch;
  border-bottom:1px solid var(--gray-1);
}
.aco-options{
  margin: 0;
  list-style: none;
  padding-left: 0;
}
.aco-option[disabled="true"]{
  color:var(--gray-2);
}
.aco-option{
  text-align: center;
  user-select: none;
}
.aco-option:not([disabled="true"]):hover{
  cursor: pointer;
  background: var(--hoverLightBlue);
  color:var(--lightBlue);
}
.aco-option{
  padding: 1ch;
}
</style>
