<template>
  <div class="value-option">
    <label class="sa-ps-ia-title">值</label>
    <template v-if="listVisible">
      <NumberInputUnit
          :ref="pushVnode"
          v-for="(obj,index) in valuesTemp"
          v-model:value="valuesTemp[index]"
          :key="index"
          :disabled="valuesTemp.length == 1"
          @delete="handleDeleteBtnClicked(index)"
          @changeValue="handleInputChangeValue"
      ></NumberInputUnit>
    </template>

    <addOptionUnit @add="handleAddOptionClicked">
    </addOptionUnit>
  </div>
</template>

<script>
import {controlPanelOptionTree, SETTING_ITEM_OPTIONS} from "../js/statisticChartConfig";
import {valueOptionMixin} from "./mixins";
import {getUniqueId} from "../../../util";

export default {
  name: "ValueOption",
  data(){
    return {
      defaultValues:[0],
      validations:[
        {
          handler:(val,id) => {
            let duplicate = this.valuesTemp.filter(_val => _val == val).length > 1;
            if(duplicate){
              return false;
            }
            return true;
          },
          tip:'数值重复'
        }
      ]
    }
  },
  mixins:[valueOptionMixin],
  computed:{
  },
}
</script>
<style scoped lang="scss">
@import "../scss/option";
</style>
