<template>
  <el-input
      @blur="handleInputBlur"
      :class="{'option-input--empty':isEmpty}"
      v-model="valueTemp"
      :disabled="disabled"
      :type="type"
      :placeholder="placeholder"
  ></el-input>

</template>

<script>

import {isNotNull} from "../../js/chartUtil";
import {checkValidation} from "../mixins";

export default {
  name: "OptionInput",
  props:{
    'modelValue':{
      type: Number,
      required:true
    },
    placeholder:{
      required:false,
    },
    type:{
      required:false,
    },
    disabled:{
      required:false,
      default:false
    },
  },
  data(){
    return {
      valueTemp:null,
      isEmpty:false,
    }
  },
  watch:{
    modelValue:{
      handler:function(newVal){
        this.valueTemp = newVal;
      },
      immediate:true,
    },
    valueTemp(newVal){
      this.isEmpty = !isNotNull(newVal);
      this.$emit('update:modelValue',newVal);
    },

  },
  mixins:[checkValidation],
  methods:{
    handleInputBlur(){
      this.isEmpty = !isNotNull(this.valueTemp);
    }
  }
}
</script>

<style scoped>
.option-input--empty >>> .el-input__inner:not(:focus){
  border-color: var(--el-color-error);
}
</style>
