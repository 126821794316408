<template>
  <Mask
      @clickVacancy=""
      :display="visible ? 'block' : 'none'"
  >
    <div class="save-dialog insetCenter">
      <DialogCloseBtn @click="closeDialog"></DialogCloseBtn>
      <h1 class="save-tip">保存图表</h1>
      <div class="save-input-item save-title">
        <label for="sd-ii-title" class="input-item-label">标题</label>
        <el-input v-model="title" id="sd-ii-title" placeholder="请填写"></el-input>
      </div>
      <div class="save-input-item save-desc">
        <label for="sd-ii-desc" class="input-item-label">描述</label>
        <el-input v-model="desc" id="sd-ii-desc" type="textarea" placeholder="选填"></el-input>
      </div>

      <div class="save-operate-btns">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" :disabled="!textValid" @click="saveData">保存</el-button>
      </div>
    </div>
  </Mask>
</template>

<script>
import Mask from "../../universalUI/Mask";
import {isNotNull} from "../js/chartUtil";
import DialogCloseBtn from "../../universalUI/DialogCloseBtn";
export default {
  name: "SaveDialog",
  components: {DialogCloseBtn, Mask},
  props:{
    visible:{
      required:true,
    }
  },
  computed:{
    textValid(){
      return isNotNull(this.title)
    }
  },
  data(){
    return {
      title:'',
      desc:''
    }
  },
  methods:{
    closeDialog(){
      this.$emit('close');
    },
    saveData(){
      this.$emit('save',this.title,this.desc);
    }
  }
}
</script>

<style scoped>
.save-tip,
.save-input-item{
  text-align: left;
}
.save-input-item + .save-input-item{
  margin-top: 1.5ch;
}
.save-dialog{
  --sd-horizontalWidth:24px;
  --sd-verticalHeight:24px;

  width:400px;
  background: white;
  border-radius: 4px;
  border:1px solid var(--border-gray);
}
.save-tip,
.save-input-item
{
  padding: 0 var(--sd-horizontalWidth);
}
.save-tip{
  margin: var(--sd-verticalHeight) 0;
}
.save-desc >>> .el-textarea__inner{
  height: 150px;
}
.save-operate-btns{
  padding: var(--sd-verticalHeight) var(--sd-horizontalWidth);
  text-align: right;
}

.save-input-item >>> .el-input__inner:not(:focus),
.save-input-item >>> .el-textarea__inner:not(:focus)
{
  background: var(--near-white);
}
.sd-topbar{
  box-sizing: border-box;
  height: 30px;
  padding: .5rem;
}
</style>
