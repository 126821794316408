<template>
  <div class="option-select" ref="optionSelect">
    <el-select v-model="valueTemp" @change="handleSelectionChanged"
               @keydown="handleSelectorKeyDown"
               @blur="handleSelectorBlur"
               class="m-2 option-select"
               :class="{'option-select--empty':isEmpty}"
               :placeholder="placeholder"
               :size="size"
               :disabled="disabled"
               :popper-class="popperClass"
               :multiple="multiple"
               :clearable="clearable"
               :filterable="filterable"
               :remote="remote"
               :collapseTags="collapseTags"
               :automaticDropdown="automaticDropdown"
               :multipleLimit="multipleLimit"
               :defaultFirstOption="defaultFirstOption"
               :fitInputWidth="fitInputWidth"
    >
      <slot name="default"></slot>
    </el-select>
  </div>
</template>

<script>
import {colorLog, singleListenerManager} from "../../../../util";
import {isNotNull} from "../../js/chartUtil";
import {CHART_EVENTS} from "../../js/statisticChartConfig";
import {checkValidation} from "../mixins";

export default {
  name: "OptionSelect",
  props:{
    placeholder:{
      required:false,
      default:'请选择'
    },
    modelValue:{
      required:true,
    },
    disabled:{
      required:false,
      default:false,
    },
    size:{
      required:false,
      default:'large'
    },
    popperClass:String,
    multiple: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    remote: Boolean,
    collapseTags: Boolean,
    automaticDropdown: Boolean,
    multipleLimit: Number,
    defaultFirstOption: Boolean,
    fitInputWidth: Boolean,
    noDataText:String,
    reserveKeyword:Boolean,
  },
  watch:{
    modelValue:{
      handler:function(newVal){
        this.valueTemp = newVal;
      },
      immediate:true,
    },
    valueTemp:function(newVal){
      this.isEmpty = !isNotNull(newVal);

      this.$emit('update:modelValue',newVal);
    },
  },
  data(){
    return {
      valueTemp:null,
      isEmpty:false,
    }
  },
  mixins:[checkValidation],
  methods:{
    handleSelectionChanged(val){
      this.$emit('change',val);
    },
    handleSelectorKeyDown(e){
      this.$emit('keydown',e);
    },
    handleSelectorBlur(e){
      this.isEmpty = !isNotNull(this.valueTemp);
    },
  },
  created() {
  },
  beforeUnmount() {
  }
}
</script>

<style scoped>
.option-select .el-select{
  width: 100%;
}
.option-select--empty >>> .el-input__inner{
  border-color: var(--el-color-error);
}
</style>
