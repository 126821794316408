<template>
  <div class="fs-value-option">
          <div class="row-item">
            <p class="row-item-title">{{isFilterMulti ? '多' : ''}}值({{currentFieldType}})</p>
            <el-select v-if="isUseSelector"
                       ref="filterSettingValueRef"
                       class="filter-setting-value-selector"
                       :class="{'filter-setting-value-selector--notSelectable':currentFieldType !== FIELD_TYPES.STRING}"
                       :popper-class="isFilterValueSelectable ? `filter-setting-value-selector-popper ${popoverClass}` : `filter-setting-value-selector-popper--notSelectable ${popoverClass}`"
                       :allow-create="true"
                       :filterable="true"
                       :default-first-option="true"
                       :reserve-keyword="true"
                       :multiple="isFilterMulti"
                       :placeholder="filterValuePlaceHolder"
                       :no-data-text="isLoadingValuesOfField ? '数据加载中' :'无数据'"
                       @keydown="handleSelectorKeyDown"
                       v-model="filterTemp[
                           isFilterMulti ? FILTER_VALUE_PROPS.MULTI : FILTER_VALUE_PROPS.CERTAIN
                       ]">
              <el-option v-for="(item,index) in filterSettingValues"
                         :disabled="!isFilterValueSelectable"
                         :key="item"
                         :label="item"
                         :value="item"
              ></el-option>
            </el-select>
            <el-input v-else-if="isUseInput" v-model="filterTemp.value" :placeholder="filterValuePlaceHolder"></el-input>

            <div v-else-if="isUseValueRange" class="fs-value-range">
              <el-input @blur="handleValueRangeInputBlur" class="fs-vr-start" type="number" v-model="filterTemp.valueRange.start"></el-input>
              <span class="fs-value-range-separator">到</span>
              <el-input @blur="handleValueRangeInputBlur" class="fs-vr-end" type="number" v-model="filterTemp.valueRange.end"></el-input>
            </div>

            <el-date-picker v-else-if="isUseInterTimePicker"
                            :popper-class="`certain-picker ${popoverClass}`"
                            value-format="YYYY-MM-DD h:m:s a"
                            @focus="handleCertainPickerFocus"
                            type="datetime"
                            v-model="filterTemp.timeRange[filter.operator == FILTER_OPERATORS.AFTER ? 'start' : 'end']">
            </el-date-picker>

            <div class="fs-time-range" v-else-if="isUseTimeRange">
              <el-date-picker
                  :popper-class="popoverClass"
                  range-separator="到"
                  type="datetimerange"
                  value-format="YYYY-MM-DD h:m:s a"
                  @change="handleTimeRangePickerConfirm"
                  v-model="transformedTimeRange"></el-date-picker>
            </div>

          </div>
  </div>
</template>

<script>
import {FIELD_TYPES, FILTER_OPERATORS, FILTER_VALUE_PROPS} from "../js/statisticChartConfig";
import {colorLog, deepClone} from "../../../util";
import OptionSelect from "./common/OptionSelect";

export default {
  name: "FilterSettingValueOption",
  components: {OptionSelect},
  props:{
    filter:{
      required:true
    },
    currentFieldType:{
      required:true
    },
    filterSettingValues:{
      required:true,
    },
    popoverClass:{
      required:true,
    },
    isLoadingValuesOfField:{
      required:false,
      default:false,
    },
  },
  watch:{
    filter:{
      handler:function(val){
        this.filterTemp = val;
        this.transformedTimeRange = [val[FILTER_VALUE_PROPS.TIME_RANGE]?.start,val[FILTER_VALUE_PROPS.TIME_RANGE]?.end]
      },
      deep:true,
      immediate:true,
    },

  },
  data(){
    return {
      FIELD_TYPES,
      FILTER_OPERATORS,
      FILTER_VALUE_PROPS,
      filterTemp:null,
      multiTimeSelectorTemp:null,
      transformedTimeRange:null,
    }
  },
  computed:{
    isUseSelector(){
      if(this.currentFieldType == FIELD_TYPES.DATE) return false;
      return [
              FILTER_OPERATORS.IS_ONE_OF,
              FILTER_OPERATORS.IS_NOT_ONE_OF
            ].includes(this.filter.operator) || this.currentFieldType == FIELD_TYPES.STRING
    },
    isUseInput(){
      return this.currentFieldType == FIELD_TYPES.NUMBER &&
          [
              FILTER_OPERATORS.IS,
              FILTER_OPERATORS.IS_NOT,
          ].includes(this.filter.operator)
    },
    isUseValueRange(){
      return this.currentFieldType == FIELD_TYPES.NUMBER &&
          [
            FILTER_OPERATORS.IN_RANGE,
            FILTER_OPERATORS.OUT_OF_RANGE,
          ].includes(this.filter.operator)
    },
    isUseInterTimePicker(){
      return this.currentFieldType == FIELD_TYPES.DATE &&
          [
              FILTER_OPERATORS.BEFORE,
              FILTER_OPERATORS.AFTER,
          ].includes(this.filter.operator)
    },
    isUseTimeRange(){
      return this.currentFieldType == FIELD_TYPES.DATE &&
          [
            FILTER_OPERATORS.IN_RANGE,
            FILTER_OPERATORS.OUT_OF_RANGE,
          ].includes(this.filter.operator)
    },
    isFilterValueSelectable(){
      return this.currentFieldType == FIELD_TYPES.STRING;
    },
    isFilterMulti(){
      return [
        FILTER_OPERATORS.IS_ONE_OF,
        FILTER_OPERATORS.IS_NOT_ONE_OF
      ].includes(this.filter.operator);
    },
    filterValuePlaceHolder() {
      if (this.currentFieldType == FIELD_TYPES.STRING) {
        return '输入后请按回车(Enter)或在下拉框里选择';
      } else {
        if (this.filter.operator == FILTER_OPERATORS.IS_NOT_ONE_OF || this.filter.operator == FILTER_OPERATORS.IS_ONE_OF) {
          return '输入后请按回车(Enter)';
        } else {
          return '请输入';
        }
      }
    },
  },
  methods:{
    handleValueRangeInputBlur(val){
      if(this.filterTemp.valueRange.start == null || this.filterTemp.valueRange.end == null) return;
      if(this.filterTemp.valueRange.start == '' || this.filterTemp.valueRange.end == '') return;
      if(Number(this.filterTemp.valueRange.start) <= Number(this.filterTemp.valueRange.end)) return;

      let temp = this.filterTemp.valueRange.start;
      this.filterTemp.valueRange.start = this.filterTemp.valueRange.end;
      this.filterTemp.valueRange.end = temp;
    },
    handleSelectorKeyDown(e){
      if(this.currentFieldType != FIELD_TYPES.NUMBER) return;
      function isOperation (){
        return [8].includes(e.keyCode);
      }
      function isNumber(){
        return (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >=96 && e.keyCode <= 105);
      }

      if(!isOperation() && !isNumber()){
        e.preventDefault();
      }
    },
    handleCertainPickerFocus(e){
      //删掉'此刻'按钮
      let nodes = document.querySelectorAll('.certain-picker');
      [...nodes].forEach(node => {
        let foundNode = node.querySelector('.el-picker-panel__footer .el-button--text');
        foundNode && foundNode.remove();
      })
    },
    handleTimeRangePickerConfirm(){
      if(this.transformedTimeRange == null){
        this.filterTemp[FILTER_VALUE_PROPS.TIME_RANGE] = {start:'',end:''};
        return;
      }

      this.filterTemp[FILTER_VALUE_PROPS.TIME_RANGE].start = this.transformedTimeRange[0];
      this.filterTemp[FILTER_VALUE_PROPS.TIME_RANGE].end = this.transformedTimeRange[1];
    },
    handlePickerConfirm(val){
      this.filterTemp[FILTER_VALUE_PROPS.TIME_MULTI].push(val);
    }
  }
}
</script>
<style>
.filter-setting-value-selector-popper--notSelectable {
  display: none;
}
</style>
<style scoped>

.filter-setting-value-selector {
  display: block;
}

.filter-setting-value-selector--notSelectable >>> .el-input__suffix {
  display: none;
}
.fs-value-range{
  display: flex;
  align-items: center;
}
.fs-value-range >>> .el-input__inner{
  line-height: 20px;
}
.fs-value-range-separator{
  margin: 0 1ch;
}
.fs-multi-time{
  display: block;
  border:1px solid var(--border-gray);
  border-radius: 4px;
  transition: .2s border;
}
.fs-multi-time:focus{
  border-color:var(--lightBlue);
}
</style>
