<template>
  <div class="field-option" >
    <label class="sa-ps-ia-title">值</label>
    <div class="sa-ps-ia-input">
      <OptionSelect v-model="valueTemp"
                    @change="handleSelectionChanged"
                    placeholder="请选择字段"
      >
        <el-option-group
            v-for="group in filteredFields"
            :key="group.type"
            :label="group.type"
        >
          <el-option
              v-for="item in group.fields"
              :key="item"
              :label="item"
              :value="item"
          />
        </el-option-group>
      </OptionSelect>
    </div>
  </div>
</template>

<script>
import {SETTING_ITEM_OPTIONS,controlPanelOptionTree,AGGREGATION_TYPES} from "../js/statisticChartConfig";
import {colorLog} from "../../../util";
import OptionSelect from "./common/OptionSelect";

export default {
  name: "FieldOption",
  components: {OptionSelect},
  props:{
    value:{
      default:null
    },
    fields:{
      default:[]
    },
    aggregation:{
      required:true
    }
  },
  data(){
    return {
      valueTemp:this.value
    }
  },
  computed:{
    filteredFields(){
      if(!this.fields || !this.fields.length){
        return []
      }
      let supportTypes = AGGREGATION_TYPES[this.aggregation];
      return this.fields.filter(item => supportTypes.includes(item.type));
    }
  },
  watch:{
    value(newVal){
      this.valueTemp = newVal;
    }
  },
  methods:{
    handleSelectionChanged(val){
      this.$emit('update:value',val);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../scss/option";
</style>
