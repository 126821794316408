<template>
  <div class="filter-option disabled">
    <label class="sa-ps-ia-title">
      <span class="spi-title-text">
        过滤器 {{index}} {{ filterTemp.label ? `- ${filterTemp.label}` : '' }}
      </span>
      <div class="spi-tools">
        <span class="spi-tool el-icon-price-tag color-blue-hover" @click="handleLabelBtnClicked"></span>
        <span class="spi-tool el-icon-delete-solid color-error-hover" :class="{disabled:isLastOne}" @click="handleDeleteBtnClicked"></span>
      </div>
    </label>
    <div class="sa-ps-ia-input">
      <table class="filter-option-setting">
        <tr>
        <th>字段</th>
        <th>操作</th>
        </tr>
        <tbody>
        <tr>
          <td>
            <OptionSelect v-model="filterTemp.field" @change="handleFieldChange">
              <el-option-group
                  v-for="group in fields"
                  :key="group.type"
                  :label="group.type"
              >
                <el-option
                    v-for="item in group.fields"
                    :key="item"
                    :label="item"
                    :value="item"
                />
              </el-option-group>
            </OptionSelect>
          </td>
          <td>
            <OptionSelect v-model="filterTemp.operator" :disabled="!calcEnable" :placeholder="calcEnable ? '请输入' : '等待中'">
              <el-option
                v-for="item in currentEnableOperators"
                :value="item"
                :label="item"
                :key="item"
              ></el-option>
            </OptionSelect>
          </td>
        </tr>
        <tr>
          <th>值</th>
        </tr>
        <tr class="filter-option-value-row">
          <td colspan="2">
            <OptionInput v-if="isUseValueInput"
                      v-model="filterTemp.value"
                      :disabled="!valueEnable"
                      :type="currentFieldType == FIELD_TYPES.STRING ? 'text' : 'number'"
                      :placeholder="valueEnable ? '请输入' : '等待中'"></OptionInput>
            <el-date-picker
                v-else-if="isUseSingleDatePicker"
                type="datetime"
                value-format="YYYY-MM-DD h:m:s a"
                :disabled="!valueEnable"
                v-model="filterTemp.timeRange[
                    filterTemp.operator == AGGREGATION_FILTER_OPERATORS.AFTER ? 'start' : 'end'
                ]"
            ></el-date-picker>
            <el-date-picker
                v-else
                type="datetimerange"
                value-format="YYYY-MM-DD h:m:s a"
                :disabled="!valueEnable"
                v-model="filterTimeRangeTemp"
            ></el-date-picker>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
    <div class="filter-option-label" v-if="showLabelInput">
      <label class="sa-ps-ia-title">
      <span class="spi-title-text">
        过滤器标签 {{index}}
      </span>
      </label>
      <div class="sa-ps-ia-input">
        <el-input v-model="filterTemp.label" placeholder="选填" clearable></el-input>
      </div>
    </div>

  </div>

</template>

<script>
import {
  controlPanelOptionTree,
  SETTING_ITEM_OPTIONS,
  FIELD_TYPES,
  AGGREGATION_FILTER_OPERATORS,
  AGGREGATION_FILTER_OPERATOR_SUPPORT,
  FILTER_OPERATORS, FILTER_VALUE_PROPS, CHART_EVENTS
} from "../js/statisticChartConfig";
import {colorLog, deepClone, singleListenerManager} from "../../../util";
import {getFieldType} from "../js/chartUtil";
import OptionSelect from "./common/OptionSelect";
import OptionInput from "./common/OptionInput";

export default {
  name: "FilterOption",
  components: {OptionInput, OptionSelect},
  props:{
    value:{
      default:null
    },
    fields:{
      default:[]
    },
    aggregation:{
      required:true
    },
    filter:{
      required:false,
    },
    filters:{
      required:true
    },
    index:{
      required:true
    }
  },
  data(){
    return {
      FIELD_TYPES,
      AGGREGATION_FILTER_OPERATORS,
      AGGREGATION_FILTER_OPERATOR_SUPPORT,
      expressionText:'',
      showLabelInput:false,
      defaultFilter:{
        field:'',
        operator:'',
        value:'',
        timeRange:{
          start:'',
          end:''
        },
        label:''
      },
      //显隐控制
      dropdownVisible:false,
      filterTemp:null,
      filterTimeRangeTemp:[],
    }
  },
  watch:{
    filter:{
      handler(val){
        let keys = Object.getOwnPropertyNames(this.defaultFilter)
        for(let keyName of keys){
          if(!val[keyName]) val[keyName] = this.defaultFilter[keyName];
        }
        this.filterTimeRangeTemp = [val[FILTER_VALUE_PROPS.TIME_RANGE].start,val[FILTER_VALUE_PROPS.TIME_RANGE].end];
        this.filterTemp = val;
      },
      immediate:true
    },
    filterTimeRangeTemp:{
      handler:function(val){
        if(val.length == 0) return;
        this.filterTemp[FILTER_VALUE_PROPS.TIME_RANGE].start = val[0];
        this.filterTemp[FILTER_VALUE_PROPS.TIME_RANGE].end = val[1];
      },
      deep:true
    }
  },
  computed:{
    currentFieldType(){
      if(!this.filterTemp.field || !this.fields) return '';

      return getFieldType(this.fields,this.filterTemp.field);
    },
    currentEnableOperators(){
      return AGGREGATION_FILTER_OPERATOR_SUPPORT[this.currentFieldType];
    },
    calcEnable(){
      return !!this.filterTemp.field;
    },
    valueEnable(){
      return !!this.filterTemp.operator;
    },
    isUseValueInput(){
      return [FIELD_TYPES.NUMBER,FIELD_TYPES.STRING].includes(this.currentFieldType);
    },
    isUseSingleDatePicker(){
      return [FIELD_TYPES.DATE].includes(this.currentFieldType) &&
          [FILTER_OPERATORS.BEFORE,FILTER_OPERATORS.AFTER].includes(this.filterTemp.operator);
    },
    isLastOne(){
      return this.filters.length == 1;
    }
  },
  methods:{
    handleInputFocus(){
      colorLog.pink('handleInputFocus');
    },
    handleInputBlur(){
      colorLog.$3('handleInputBlur');
    },
    handleLabelBtnClicked(){
      this.showLabelInput = !this.showLabelInput;
      this.$nextTick(()=>{
        singleListenerManager.emit(CHART_EVENTS.EXPAND_INPUT_AREA);
      })
    },
    handleDeleteBtnClicked(){
      if(this.isLastOne) return;
      this.$emit('delete')
    },
    handleFieldChange(val){
      this.refreshSetting();
    },


    refreshSetting(){
      this.$nextTick(()=>{
        if(!this.currentEnableOperators.includes(this.filterTemp.operator)){
          this.filterTemp.operator = '';
          this.filterTemp.value = '';
        }
      })
    }
  },
  mounted() {

  }
}
</script>
<style scoped>
.filter-option{
  border: 1px solid var(--gray-2);
  padding: 0 5px 5px;
  transition: .4s border;
}
.filter-option:focus-within{
  border-color: var(--lightBlue);
}
.el-icon-delete-solid.disabled[class]{
  color:var(--gray-3);
}
.filter-option-setting{
  /*display: flex;*/
  font-size: 1rem;
  width: 100%;
}
.filter-option-setting  .el-select{
  width: 100%;
}
.filter-option-setting >>> .el-input__inner{
  line-height: 20px;
}
.filter-option-value-row >>> .el-date-editor{
  width: 100%;
}
</style>
<style scoped lang="scss">
@import "../scss/option";
</style>
