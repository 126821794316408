<template>
  <div class="order-by-option">
    <label class="sa-ps-ia-title">顺序参考</label>
    <div class="sa-ps-ia-input">
      <OptionSelect
          v-model="selectedValue"
          @change="handleChange"
      >
        <el-option v-for="option in orderOptions"
                   :label="option.label"
                   :key="option.value"
                   :value="option.value"
        ></el-option>
      </OptionSelect>
    </div>

  </div>
</template>

<script>
import {controlPanelOptionTree, SETTING_ITEM_OPTIONS,AGGREGATIONS_ORDER_LIST_PRODUCER,DEFAULT_ORDER_BY_OPTIONS} from "../js/statisticChartConfig";
import {colorLog, deepClone} from "../../../util";
import OptionSelect from "./common/OptionSelect";

export default {
  name: "OrderByOption",
  components: {OptionSelect},
  props:{
    orderByInfo:{
      required:true
    },
    aggregation:{
      required:true
    },
    metricsConfigs:{
      required:true
    }
  },
  data(){
    return {
      valueTemp:null,
      selectedValue:null,
    }
  },
  watch:{
    orderByInfo:{
      handler:function(val){
        this.updateSelectedValue();
      },
      immediate:true
    },
    metricsConfigs:{
      handler:function(val){
        this.updateSelectedValue();
      },
      deep:true
    },
    selectedValue(newVal){
      colorLog.blue('update:orderByInfo',newVal);
      this.$emit('update:orderByInfo',this.orderOptions.find(option => option.value === newVal).info);
    }
  },
  computed:{
    orderOptions(){
      if(!this.metricsConfigs) return deepClone(DEFAULT_ORDER_BY_OPTIONS);

      let options = this.metricsConfigs.reduce((options,metricConfig)=>{
        let producedOptions;
        let producer = AGGREGATIONS_ORDER_LIST_PRODUCER[metricConfig.aggregation];

        if(producer){
          producedOptions = producer(metricConfig);
        }else{
          if(!metricConfig[SETTING_ITEM_OPTIONS.FIELD]) {
            producedOptions = []
          }else{
            producedOptions = AGGREGATIONS_ORDER_LIST_PRODUCER.DEFAULT_PRODUCER(metricConfig);
          }
        }
        return options.concat(producedOptions);
      },[]);

      options = options.concat(deepClone(DEFAULT_ORDER_BY_OPTIONS));
      return options;
    }
  },
  created() {
    // this.updateSelectedValue();
  },
  methods:{
    updateSelectedValue(){
      if(!this.orderByInfo) {
        this.selectedValue = this.orderOptions[0].value;
        this.$emit('update:orderByInfo',this.orderOptions[0].info);
        return;
      }

      let keyNames = Object.getOwnPropertyNames(this.orderByInfo);
      let foundInfo = this.orderOptions.find(optionInfo =>{
        return keyNames.every(keyName => {
          if(!optionInfo.info) return false;
          return optionInfo.info[keyName] === this.orderByInfo[keyName]
        });
      });
      if(!foundInfo){
        this.selectedValue = this.orderOptions[0].value;
        return;
      }
      this.selectedValue = foundInfo.value;
    },
    handleChange(newOrderInfo){

    }
  }
}
</script>

<style scoped lang="scss">
@import '../scss/option';
</style>
