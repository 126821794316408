import {colorLog, getUniqueId, singleListenerManager} from "../../../util";
import NumberInputUnit from "./unit/NumberInputUnit";
import AddOptionUnit from "./unit/AddOptionUnit";
import {
    AGGREGATIONS,
    TIME_INTERVAL,
    controlPanelOptionTree,
    SETTING_ITEM_OPTIONS,
    CHART_EVENTS
} from "../js/statisticChartConfig";
import {difference} from "lodash";
import {isNotNull} from "../js/chartUtil";
export const valueOptionMixin = {
    components: {NumberInputUnit, AddOptionUnit},
    props:{
        values:{
            required:false
        },
        aggregation:{
            required:true
        },
        index:{
            required:true
        }
    },
    data(){
        return {
            listVisible:true,
            inputVnodes:[],
            taskQueue:[],
            defaultValidations:[{
                handler:(val)=>{
                    // this.taskQueue.push(()=>{
                    //     this.$emit('broadcast',{source:this.index,executeFunction:(valueNodes,index)=>{
                    //         if(index != )
                    //         }});
                    // })
                    return isNotNull(val);
                },
                tip:'值不能为空'
            }],
            valuesTemp:null,
        }
    },
    watch:{
        values:{
            handler:function(vals){
                if(!vals || vals.length == 0){
                    this.$emit('update:values',[...this.defaultValues]);
                    return;
                }
                this.valuesTemp = vals;
            },
            immediate:true,
            deep:true,
        },
    },
    computed:{
        valueValidated(){
            if(!this.valuesTemp) return false;
            return this.valuesTemp.every((val,index) => {
                return this.validations.every(validation => validation.handler(Number(val)));
            })
        }
    },
    created() {
        this.validations = this.validations.concat(this.defaultValidations);
    },
    beforeUpdate() {
        this.inputVnodes = [];
    },
    methods:{
        getUniqueId,
        handleInputChangeValue(){
        },
        handleAddOptionClicked(){
            this.valuesTemp.push(Number(this.valuesTemp.slice(-1)[0]) + 1);
        },
        handleDeleteBtnClicked(index){
            if(this.valuesTemp.length == 1) return;
            this.listVisible = false;
            let temp = this.valuesTemp;
            this.valuesTemp.splice(index,1);
            this.$nextTick(()=>{
                this.listVisible = true;
                this.$nextTick(()=>{
                    singleListenerManager.emit(CHART_EVENTS.EXPAND_INPUT_AREA);
                })
            })
        },
        pushVnode(e){
            this.inputVnodes.push(e);
        },
        getNumberInputValidations(){
            return this.inputVnodes.map(vnode => !vnode.invalid);
        }
    },
}


export const checkValidation = {
    methods:{
        handleCheckValidation(){
            this.isEmpty = !isNotNull(this.valueTemp);
        }
    },
    created() {
        singleListenerManager.on(CHART_EVENTS.CHECK_INPUT_VALIDATION,this.handleCheckValidation);
    },
    beforeUnmount() {
        singleListenerManager.off(CHART_EVENTS.CHECK_INPUT_VALIDATION,this.handleCheckValidation);
    }
}
