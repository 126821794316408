<template>
  <div class="order-sort-option">
    <div class="order-sort-item osi-selector">
      <p class="osi-title">排序</p>
      <OptionSelect v-model="orderSortTemp.order">
        <el-option v-for="sort in ORDER_SORT"
                   :label="sort"
                   :value="sort"
        ></el-option>
      </OptionSelect>
    </div>
    <div class="order-sort-item" style="flex:0 0 20%;margin-left:3%;">
      <p class="osi-title">大小</p>
      <el-input type="number" v-model="orderSortTemp.size"></el-input>
    </div>
  </div>
</template>

<script>
import {ORDER_SORT} from "../js/statisticChartConfig";
import OptionSelect from "./common/OptionSelect";

export default {
  name: "OrderSortOption",
  components: {OptionSelect},
  props:{
    orderSort:{
      required:false,
    },
  },
  watch:{
    orderSort:{
      handler:function(val){
        this.orderSortTemp = val;
      },
      immediate:true,
      deep:true,
    }
  },
  data(){
    return {
      ORDER_SORT,
      orderSortTemp:null,
    }
  }
}
</script>
<style scoped>
.osi-title{
  margin: 0;
}
.order-sort-option{
  display:flex;
}
.osi-selector{
  flex:1;
}
.osi-selector .el-select{
  width: 100%;
}
</style>
<style scoped lang="scss">
@import '../scss/option';
</style>
